import { interface___modal } from "../../interfaces/interface.modals";

//	P R O P S

export interface props___MdEntryCookiesModal extends interface___modal {

}

//	S T A T E

export interface state___MdEntryCookiesModal {

	ionModal___MdEntryCookiesModal___isOpen: boolean,

}

//	S T A T E   D E F A U L T S

export const state___MdEntryCookiesModal___default: state___MdEntryCookiesModal = {

	ionModal___MdEntryCookiesModal___isOpen: false,

}