import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___App } from '../../../App.locale';
import { locale___SbInfoLicencesPage } from './sb-info-licences.locale';

//	S T Y L E

import './sb-info-licences.scss';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';

//	C L A S S

class SbInfoLicencesPage extends React.Component<{}, {}>
{

//#region 																							D E C L A R A T I O N S
	
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/settings" text={ this._LOCALE.translate(locale___App.ionBackButton___text) } />
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<section className="sb-info-licences---container">

						<IonListHeader>
							<IonLabel>{ this._LOCALE.translate(locale___SbInfoLicencesPage.title) }</IonLabel>
						</IonListHeader>
						
						<br/>
						<br/>

						<IonCard>
							<IonList>
								<IonItem target="_blank" className="is-ion-item-button" href="https://www.apple.com/it/legal/sla/">
									<img src="../../assets/libraries/apple.icon.svg"/>
									<IonLabel>Apple</IonLabel>
								</IonItem>
								<IonItem target="_blank" className="is-ion-item-button" href="https://www.apple.com/legal/internet-services/maps/terms-en.html/">
									<img src="../../assets/libraries/applemaps.icon.svg"/>
									<IonLabel>Apple Maps</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

						<br />

						<IonCard>
							<IonList>
								<IonItem target="_blank" className="is-ion-item-button" href="https://www.freepikcompany.com/legal">
									<img src="../../assets/libraries/flaticon.icon.svg"/>
									<IonLabel>FlatIcon</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

						<br />

						<IonCard>
							<IonList>
								<IonItem target="_blank" className="is-ion-item-button" href="https://policies.google.com/">
									<img src="../../assets/libraries/google.icon.svg"/>
									<IonLabel>Google</IonLabel>
								</IonItem>
								<IonItem target="_blank" className="is-ion-item-button" href="https://cloud.google.com/maps-platform/terms">
									<img src="../../assets/libraries/googlemaps.icon.svg"/>
									<IonLabel>Google Maps</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

						<br />

						<IonCard>
							<IonList>
								<IonItem target="_blank" className="is-ion-item-button" href="https://fontawesome.com/license">
									<img src="../../assets/libraries/fontawesome.icon.svg"/>
									<IonLabel>Font Awesome</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

						<br />

						<IonCard>
							<IonList>
								<IonItem target="_blank" className="is-ion-item-button" href="https://www.mapbox.com/legal/tos">
									<img src="../../assets/libraries/mapbox.icon.svg"/>
									<IonLabel>Mapbox</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

						<br />

						<IonCard>
							<IonList>
								<IonItem target="_blank" className="is-ion-item-button" href="https://www.prysmlab.com/site/privacy-policy/">
									<img src="../../assets/libraries/prysm.icon.svg"/>
									<IonLabel>PRYSM</IonLabel>
								</IonItem>
								<IonItem target="_blank" className="is-ion-item-button" href="https://www.carshub.app/privacy-policy/">
									<img src="../../assets/libraries/carshub.icon.png"/>
									<IonLabel>CarsHub</IonLabel>
								</IonItem>
								<IonItem target="_blank" className="is-ion-item-button" href="https://www.nopr.me/privacy-policy/">
									<img src="../../assets/libraries/nopr.icon.svg"/>
									<IonLabel>NoPR</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

						<br/>
						<br/>

					</section>

				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbInfoLicencesPage);