import React from 'react'
import { SwiperSlide, Swiper } from 'swiper/react';
import { Pagination } from 'swiper/modules';

//	S T Y L E

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import './com-gallery-slider.scss';

//	P R O P S

import { props___ComGallerySliderComponent, state___ComGallerySliderComponent, state___ComGallerySliderComponent___default } from './com-gallery-slider.state';

//	M O D A L S

import MdMediaViewModal from '../../modals/md-media-view/md-media-view';

//	C L A S S

export default class ComGallerySliderComponent extends React.Component<props___ComGallerySliderComponent, state___ComGallerySliderComponent>
{

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComGallerySliderComponent,
	) {
		super(props);
		this.state = state___ComGallerySliderComponent___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___media_preview___show = (___media_src: string) => {
		this.setState({
			ionModal___MdMediaViewModal___argument: ___media_src,
			ionModal___MdMediaViewModal___visible: true,
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>

			<MdMediaViewModal isOpen={ this.state.ionModal___MdMediaViewModal___visible } media_src={ this.state.ionModal___MdMediaViewModal___argument } event___onDidDismiss={() => { this.setState({ ionModal___MdMediaViewModal___visible: false, ionModal___MdMediaViewModal___argument: '' }); }}/>

			<Swiper className="com-gallery-slider---component" modules={[ Pagination ]} pagination={{ clickable: true }}>
				{this.props.gallery___images.map((___g_p: string, ___g_p___i: number) => {
					return <>
						<SwiperSlide key={ ___g_p___i }>
							<img key={ ___g_p___i } src={ ___g_p } onClick={() => { this.handle___media_preview___show(___g_p); }} style={{aspectRatio:this.props.image___aspect_ratio}}/>
						</SwiperSlide>
					</>;
				})}
			</Swiper>

		</>;
	}

//#endregion

}