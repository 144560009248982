import React from 'react'
import { IonItem, IonLabel, IonNote } from '@ionic/react';

//	S T Y L E

import './com-digitalmenu-dish.scss'

//	S T A T E   -   P R O P S

import { props___ComDigitalMenuDishComponent } from './com-digitalmenu-dish.state';

//	F U N C S

import { funcs_measurements } from '../../funcs/funcs.measurements';
import { funcs_numbers } from '../../funcs/funcs.numbers';

//	C L A S S

export default class ComDigitalMenuDishComponent extends React.Component<props___ComDigitalMenuDishComponent, {}>
{

//#region 																							H A N L D E R S

	private readonly funcs___measurements: funcs_measurements = new funcs_measurements();
	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonItem className="com-digitalmenu-dish---container" onClick={() => { this.props.event___onClick(this.props.dish_props['dish_id']); }}>
				<IonLabel>{ this.props.dish_props['dish_name'] }</IonLabel>
				<IonNote slot="end" color="primary">
					{(this.props.dish_props['dish_measurement_unit'] && this.props.dish_props['dish_measurement_unit'] !== null) ? <>
						<b>{ this.funcs___numbers.format___price(this.props.dish_props['dish_price'], '€') }</b>
						<span> { this.funcs___measurements.price_at_measurements(this.props.dish_props['dish_measurement_unit'], false) }</span>
					</> : <>
						<b>{ this.funcs___numbers.format___price(this.props.dish_props['dish_price'], '€') }</b>
					</>}
				</IonNote>
			</IonItem>
		</>;
	}

//#endregion

}