import React from 'react'

//	L O C A L I Z A T I O N

import { locale___Com404Component } from './com-404.locale';

//	S T Y L E

import './com-404.scss'

//	S T A T E   P R O P S

import { props___Com404Component } from './com-404.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class Com404Component extends React.Component<props___Com404Component, {}>
{

//#region 																							R E N D E R

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className={ 'com-404---container ' + ((this.props.theme && this.props.theme === 'dark') ? ' com-404---container---is-dark ' : ' ')}>
			
				<img src="/assets/ui/images/image-404.svg" alt="404" />
			
				<h3>{ this._LOCALE.translate(locale___Com404Component.title) }</h3>
				<p>{ this._LOCALE.translate(locale___Com404Component.message) }</p>
				<span>{ this._LOCALE.translate(locale___Com404Component.footer) }</span>
			
			</div>
		</>;
	}

//#endregion

}