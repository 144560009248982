//	L I B S

import { lib_emoji } from "../libs/lib.emoji";

//	T Y P E S

import { type___text___emoji } from "../types/types.text";

//	E X P O R T   F U N C S

export class funcs_text
{

//#region 																							I N P U T   T E X T   P R O C E S S

	public text_input___process = (___input_string: string) : string => {
		let ___output___string: string = ___input_string;
		___output___string = this.text_input___replace_emoji(___input_string);
		return ___output___string as string;
	}

	public text_input___process___render = (___input_string: string) : string => {
		let ___output___string: string = ___input_string;
		___output___string = ___output___string.replace(/\n/g, ' \n');
		return ___output___string as string;
	}

//#endregion

//#region 																							I N P U T   R E P L A C E   E M O J I

	private text_input___replace_emoji = (___input_string: string) : string => {
		let ___output___string: string = ___input_string;
		for (const ___e in lib_emoji) {
			const ___emojiData: type___text___emoji = lib_emoji[___e];
			const ___emojiData___regex: RegExp = new RegExp(___emojiData.text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g');
			___output___string = ___output___string.replace(___emojiData___regex, ___emojiData.emoji);
		}
		return ___output___string as string;
	}

//#endregion

//#region 																							P R O C E S S   O N L Y   L E T T E R S

	public text___process___only_letters(___input_text: string) : string
	{
		const ___input_text___letters_only: string = ___input_text.replace(/[^a-zA-Z]/g, '');
		return ___input_text___letters_only as string;
	}

//#endregion


}