import React from 'react';
import { IonContent, IonPage, withIonLifeCycle } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___SbLandingGeolocationPage } from './sb-landing-geolocation.locale';

//	S T Y L E

import './sb-landing-geolocation.scss';

//	S T A T E   -   P R O P S

import { props___SbLandingGeolocationPage, state___SbLandingGeolocationPage, state___SbLandingGeolocationPage___default } from './sb-landing-geolocation.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';
import { service_GeolocationService } from '../../../services/service-geolocation';

//	M O D A L S

import MdInfoEulaModal from '../../../modals/md-info-eula/md-info-eula';

//	C L A S S

class SbLandingGeolocationPage extends React.Component<props___SbLandingGeolocationPage, state___SbLandingGeolocationPage>
{

//#region 																							D E C L A R A T I O N S
	
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _GEOLOCATION: service_GeolocationService = new service_GeolocationService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbLandingGeolocationPage,
	) {
		super(props);
		this.state = state___SbLandingGeolocationPage___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___geolocation___ask = async () => {
		await this._GEOLOCATION.geolocation___locate();
		this.props.event___onGeolocationAvailable();
	}

//#endregion

//#region 																							H A N D L E R S

	ionViewDidEnter() : void {
		this.handle___geolocation___ask();
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<MdInfoEulaModal isOpen={ this.state.ionModal___MdEntryCookiesModal___isOpen } event___onDidDismiss={() => { this.setState({ ionModal___MdEntryCookiesModal___isOpen: false });}} />

				<IonContent forceOverscroll={ false }>
					<section className="sb-landing-geolocation---container">

						<img src="/assets/logo.horizontal.svg"/>

						<br />
						<br />
				
						<h4>{ this._LOCALE.translate(locale___SbLandingGeolocationPage.title) }</h4>
						
						<div>
							<span>{ this._LOCALE.translate(locale___SbLandingGeolocationPage.description_1) }</span>
							<span>{ this._LOCALE.translate(locale___SbLandingGeolocationPage.description_2) }</span>
							<span onClick={() => { this.setState({ ionModal___MdEntryCookiesModal___isOpen: true, }); }}>{ this._LOCALE.translate(locale___SbLandingGeolocationPage.more_info) }</span>
						</div>

					</section>
				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbLandingGeolocationPage);