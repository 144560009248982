import React from 'react'

//	L O C A L I Z A T I O N

import { locale___ComPoiTimetableTableComponent } from './com-poi-timetable-table.locale';

//	T Y P E S

import { type___poi___timetable___day_index, type___poi___timetable___hour } from '../../types/types.types';

//	S T Y L E

import './com-poi-timetable-table.scss'

//	S T A T E S   -   P R O P S

import { props___ComPoiTimetableTableComponent } from './com-poi-timetable-table.state';

//	F U N C S

import { funcs_datetime } from '../../funcs/funcs.datetime';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class ComPoiTimetableTableComponent extends React.Component<props___ComPoiTimetableTableComponent, {}>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	
	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		const ___return___days_of_the_week: type___poi___timetable___day_index[] = [ 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
		return <>
			<section className="com-poi-timetable-table---container">
				{___return___days_of_the_week.map((___day_index: type___poi___timetable___day_index) => {
					return <>
						<div key={ ___day_index }>
							<div>
								<p>{ this.funcs___datetime.datetime___get___dayOfTheWeek(___day_index) }</p>
							</div>
							{(this.props.timetable_week_props[___day_index].length > 0) ? <>
								<div>
									{this.props.timetable_week_props[___day_index].map((___t: type___poi___timetable___hour, ___t___idx: number) => {
										return <>
											<div key={ ___t___idx }>
												{ ___t.from } - { ___t.to }
											</div>
										</>;
									})}
								</div>
							</> : <>
								<div>{ this._LOCALE.translate(locale___ComPoiTimetableTableComponent.closed___day) }</div>
							</>}
						</div>
					</>;
				})}
			</section>
		</>;
	}

//#endregion

}