import React from 'react'

//	S T Y L E

import './com-gallery-placeholder.scss';

//	P R O P S

import { props___ComGalleryPlaceholderComponent } from './com-gallery-placeholder.state';

//	C L A S S

export default class ComGalleryPlaceholderComponent extends React.Component<props___ComGalleryPlaceholderComponent, {}>
{

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComGalleryPlaceholderComponent,
	) {
		super(props);
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<section className="com-gallery-placeholder---component">
				<img src={ this.props.placeholder_path } />
				<img src={ this.props.placeholder_path } />
			</section>
		</>;
	}

//#endregion

}