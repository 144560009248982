import React from 'react'

//	L O C A L I Z A T I O N

import { locale___ComCopyrightComponent } from './com-copyright.locale';

//	S T Y L E

import './com-copyright.scss'

//	S E R V I C E  S

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class ComCopyrightComponent extends React.Component<{}, {}>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="com-copyright-component---container">
				<span><b>{ this._LOCALE.translate(locale___ComCopyrightComponent.row1, [(new Date()).getFullYear().toString()]) }</b></span>
				<span>{ this._LOCALE.translate(locale___ComCopyrightComponent.row2) }</span>
				<span>{ this._LOCALE.translate(locale___ComCopyrightComponent.row3) }</span>
			</div>
		</>;
	}

//#endregion

}