import { type___map___element___type } from '../../types/types.types'

//	P R O P S

export interface props___SbDiscoverMapPage {

}

//	S T A T E

export interface state___SbDiscoverMapPage {

	discover___search___param: string,

	discover___search_cities: any[],
	discover___search_cities___isLoading: boolean,
	discover___search_poi: any[],
	discover___search_poi___isLoading: boolean,

	map___isLoading___events: boolean,
	map___isLoading___poi: boolean,

	map___element_pane: 'element' | 'navigation',
	map___element_pane___content_type: type___map___element___type,
	map___element_pane___content_id: string | null,
	map___element_pane___content_props: any | null,

	ionModal___MdDiscoverCompassModal___isOpen: boolean,
	ionModal___MdDiscoverSearchModal___isOpen: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbDiscoverMapPage___defaults: state___SbDiscoverMapPage = {

	discover___search___param: '',

	discover___search_cities: [],
	discover___search_cities___isLoading: false,
	discover___search_poi: [],
	discover___search_poi___isLoading: false,

	map___isLoading___events: false,
	map___isLoading___poi: false,

	map___element_pane: 'navigation',
	map___element_pane___content_type: null,
	map___element_pane___content_id: null,
	map___element_pane___content_props: null,

	ionModal___MdDiscoverCompassModal___isOpen: false,
	ionModal___MdDiscoverSearchModal___isOpen: false,

}