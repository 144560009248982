import React from 'react';
import { IonContent, IonModal } from '@ionic/react';

//	T Y P E S

import { type___modal___config } from '../../types/types.modals';

//	S T Y L E

import './md-media-view.scss';

//	S T A T E   -   P R O P S

import { props___MdMediaViewModal } from './md-media-view.state';

//	C L A S S

export default class MdMediaViewModal extends React.Component<props___MdMediaViewModal, {}>
{

//#region 																							C O N F I G

	private readonly MdMediaViewModal___modal_config: type___modal___config = {
		backdropDismiss: false,
		handle: false,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); },
	};

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdMediaViewModal,
	)
	{
		super(props);
	}

//#endregion

//#region 																							L I F E C Y C L E

	private async event___onDidPresent() : Promise<void>
	{

	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonModal isOpen={ this.props.isOpen } { ...this.MdMediaViewModal___modal_config }>
				<IonContent forceOverscroll={ false }>

					<section className="md-media-view---container">
						{(this.props.isOpen === true) ? <>
							<img src={ this.props.media_src }/>
							<img src={ this.props.media_src }/>
						</> : <></>}
					</section>

					<div className="md-media-view---close">
						<i className="fas fa-chevron-down" onClick={ this.props.event___onDidDismiss }></i>
					</div>

				</IonContent>
			</IonModal>
		</>;
	}

//#endregion

}