//	T Y P E S

import { type___cookies___accepted } from "./types/types.cookies";
import { type___locale___language } from "./types/types.locale";

//	S T A T E

export interface state___App {

	app___isLoading: boolean,

	entry___language_selected: type___locale___language | null,
	entry___cookies_accepted: type___cookies___accepted | null,
	entry___geolocation_available: boolean | null,

	ionModal___MdEntryCookiesModal___isOpen: boolean,
	ionModal___MdEntryDownloadAppModal___isOpen: boolean,
	ionModal___MdEntryLanguageModal___isOpen: boolean,

}

//	S T A T E   D E F A U L T S

export const state___App___default: state___App = {

	app___isLoading: true,

	entry___language_selected: null,
	entry___cookies_accepted: null,
	entry___geolocation_available: null,

	ionModal___MdEntryCookiesModal___isOpen: false,
	ionModal___MdEntryDownloadAppModal___isOpen: false,
	ionModal___MdEntryLanguageModal___isOpen: false,

}