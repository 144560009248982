import React from 'react';
import { IonCard } from '@ionic/react';
import { Link } from 'react-router-dom';

//	L O C A L I Z A T I O N S

import { locale___MdDiscoverSearchPreviewModal } from './md-discover-search-preview.locale';

//	S T Y L E

import './md-discover-search-preview.scss';

//	S T A T E   -   P R O P S

import { props___MdDiscoverSearchPreviewModal } from './md-discover-search-preview.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C O M P O N E N T S

import ComListEmptyComponent from '../../components/com-list-empty/com-list-empty';
import ComSpinnerInlineComponent from '../../components/com-spinner-inline/com-spinner-inline';
import { type___geo___lngLat } from '../../types/types.types';

//	C L A S S

export default class MdDiscoverSearchPreviewModal extends React.Component<props___MdDiscoverSearchPreviewModal, {}> {

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdDiscoverSearchPreviewModal,
	) {
		super(props);
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___discover_search___dismiss = (___go_to_full: boolean) => {
		this.props.event___onDidDismiss(___go_to_full);
	}

	private handle___onMapRedirecting___process = (___sr_lng: number, ___sr_lat: number) => {
		const ___new_map_coords: type___geo___lngLat = { lng: ___sr_lng, lat: ___sr_lat };
		this.props.event___onMapRedirecting(___new_map_coords);
		this.props.event___onDidDismiss();
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<section className="md-discover-search-preview---opacizer" onClick={() => { this.handle___discover_search___dismiss(false); }}></section>
			<IonCard className="md-discover-search-preview---container">
				{(this.props.search_results___collection___isLoading === true) ? <>
					<br />
					<ComSpinnerInlineComponent/>
					<br />
				</> : <>
					{(this.props.search_results___collection.length > 0) ? <>
						<section>
							{this.props.search_results___collection.map((___sr: any, ___sr___idx: number) => {
								return <>
									{(() => {
										switch (___sr['result_type'])
										{
											case 'location': return <>
												<span key={ ___sr___idx } onClick={() => { this.handle___onMapRedirecting___process(___sr['result_coord_lng'], ___sr['result_coord_lat']) }}>
													<i className="fas fa-city"></i>
													<span>{ ___sr['result_name'] }</span>
												</span>
											</>; break;
											case 'category': return <>
												<Link key={ ___sr___idx } to={ '/view/poi/category/' + ___sr['result_id'] }>
													{(___sr['result_icon'] !== null) ? <><img src={ '/assets/markers/poi/' + ___sr['result_icon'] + '.svg' }/></> : <></>}
													<span>{ ___sr['result_name'] }</span>
												</Link>
											</>; break;
											case 'poi': return <>
												<Link key={ ___sr___idx } to={ '/view/poi/' + ___sr['result_id'] }>
													{(___sr['result_icon'] !== null) ? <><img src={ '/assets/markers/poi/' + ___sr['result_icon'] + '.svg' }/></> : <></>}
													<span>{ ___sr['result_name'] }</span>
												</Link>
											</>; break;
										}
									})()}
								</>;
							})}
							<div key={ 99999 } onClick={() => { this.handle___discover_search___dismiss(true); }}>
								<span>{ this._LOCALE.translate(locale___MdDiscoverSearchPreviewModal.load_more) }</span>
							</div>
						</section>
					</> : <>
						<ComListEmptyComponent text={ this._LOCALE.translate(locale___MdDiscoverSearchPreviewModal.no_results)} />
					</>}
				</>}
			</IonCard>
		</>;
	}

//#endregion

}