import { shareOnMobile } from "react-mobile-share";

//	L O C A L I Z A T I O N

import { locale___service_Sharing } from "./service-sharing.locale";

//	L I B S

import { lib_names_shared } from "../libs/lib.names.shared";

//	S E R V I C E S

import { service_LocalizationService } from "./service-localization";

//	E X P O R T

export class service_Sharing {

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							G E T   I D

	public async share(___title: string, ___url_route: string): Promise<'shared'|'copyed'|null>
	{
		
		const ___url___complete: string = lib_names_shared.endpoints.sharing + ___url_route;
		const ___url___complete___copy: string = this._LOCALE.translate(locale___service_Sharing.share_text, [___title]) + ___url___complete;

		try {

			shareOnMobile({
				text: this._LOCALE.translate(locale___service_Sharing.share_text, [___title]),
				url: ___url___complete,
				title: ___title,
			});

		} catch (___e) {
			
			console.log('Using Navigator   -->   ', ___e);

			if (navigator.share)
			{
				try {
					await navigator.share({
						title: ___title,
						text: this._LOCALE.translate(locale___service_Sharing.share_text, [___title]),
						url: ___url___complete
					});
					return 'shared';
				} catch (___e_n: any) {
					console.log('Error sharing   -->   ', ___e_n);
				}
			}
	
			try {
				await navigator.clipboard.writeText(___url___complete___copy);
				return 'copyed';
			} catch (___e_c: any) {
				console.error('Error copyng   -->   ', ___e_c);
			}

		}

		return null;

	}

//#endregion

}