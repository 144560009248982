import React from 'react'
import { IonCard } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___ComPoiDisclaimerAcceptanceComponent } from './com-poi-disclaimer-acceptance.locale';

//	S T Y L E

import './com-poi-disclaimer-acceptance.scss'

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class ComPoiDisclaimerAcceptanceComponent extends React.Component<{}, {}>
{

//#region 																							C O N S T R U C T O R
		
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonCard className="com-poi-disclaimer-acceptance---component" color="warning">
				<h6>{ this._LOCALE.translate(locale___ComPoiDisclaimerAcceptanceComponent.title) }</h6>
				<span>{ this._LOCALE.translate(locale___ComPoiDisclaimerAcceptanceComponent.disclaimer) }</span>
				<span>{ this._LOCALE.translate(locale___ComPoiDisclaimerAcceptanceComponent.duration) }</span>
			</IonCard>
		</>;
	}

//#endregion

}