import React from 'react'

//	L O C A L E

import { locale___ComListEmptyComponent } from './com-list-empty.locale';

//	S T Y L E

import './com-list-empty.scss'

//	P R O P S

import { props___ComListEmptyComponent } from './com-list-empty.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class ComListEmptyComponent extends React.Component<props___ComListEmptyComponent, {}>
{

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="com-list-empty---component">
				{(this.props.text && this.props.text.length > 0) ? <>
					<p>{ this.props.text }</p>
				</> : <>
					{(this.props.list_items_quantity && this.props.list_items_quantity > 0) ? <>
						<p>{ this._LOCALE.translate(locale___ComListEmptyComponent.no_more_results) }</p>
					</> : <>
						<p>{ this._LOCALE.translate(locale___ComListEmptyComponent.no_results) }</p>
					</>}
				</>}
			</div>
		</>;
	}

//#endregion

}