export const locale___ComEventComponent = {
	'event_ended': {
		de_de: 'Veranstaltung beendet',
		en_us: 'Event ended',
		es_es: 'Evento finalizado',
		fr_fr: 'Événement terminé',
		it_it: 'Evento terminato',
	},
	'event_is_now': {
		de_de: 'Jetzt! {{1}} von dir entfernt',
		en_us: 'Now! {{1}} far from you',
		es_es: '¡Ahora! {{1}} de ti',
		fr_fr: 'Maintenant! Eloigné de toi {{1}}',
		it_it: 'Adesso! A {{1}} da te',
	},
	'event_begin': {
		de_de: 'von {{1}} Uhr',
		en_us: 'from {{1}}',
		es_es: 'de {{1}} horas',
		fr_fr: 'de {{1}}',
		it_it: 'dalle {{1}}',
	},
	'far_from_you': {
		de_de: '{{1}} von dir entfernt',
		en_us: '{{1}} far from you',
		es_es: '{{1}} de ti',
		fr_fr: 'éloigné de toi {{1}}',
		it_it: 'distante da te {{1}}',
	},
	'far_from_you___loading': {
		de_de: 'berechnung der entfernung...',
		en_us: 'calculating the distance...',
		es_es: 'calculando la distancia...',
		fr_fr: 'calculer la distance...',
		it_it: 'calcolando la distanza...',
	},
};