//	L O C A L E S

import { locale___funcs_numbers } from "./funcs.numbers.locale";

//	T Y P E S

import { type___vehicle___power } from "../types/types.types";

//	S E R V I C E S

import { service_LocalizationService } from "../services/service-localization";

//	E X P O R T   F U N C S

export class funcs_numbers
{

//#region 																							N U M B E R S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							N U M B E R S

	public number___format_decimal(___value: number, ___decimal: number = 0) : string
	{
		const ___value___formatter: Intl.NumberFormat = new Intl.NumberFormat('de-DE', {
			minimumFractionDigits: ___decimal,
			maximumFractionDigits: ___decimal,
		});
		return ___value___formatter.format(___value) as string;
	}

//#endregion

//#region 																							D I S T A N C E

	public number___format_as_string___distance(___value: number | string) : string
	{
		
		const ___distance: number = typeof ___value === 'string' ? parseFloat(___value) : ___value;

		if (isNaN(___distance)) { return '-'; }
		if (___distance < 1) { return Math.floor(___distance * 1000).toString() + ' ' + this._LOCALE.translate(locale___funcs_numbers.unit___meters); }
		else if (___distance < 10) { return this.number___format_decimal(___distance, 2).toString() + ' ' + this._LOCALE.translate(locale___funcs_numbers.unit___kms); }
		else { return this.number___format_decimal(___distance, 0).toString() + ' ' + this._LOCALE.translate(locale___funcs_numbers.unit___kms); }
	
	}

//#endregion

//#region 																							P A D

	public pad___twoDigits(___value: number) : string
	{
		const ___value___to_return: string =  ___value.toString().padStart(2, '0');
		return ___value___to_return;
	}

//#endregion


//#region 																							C O N V E R T   P O W E R

public convert___power(___value: string | number, ___convert_to: type___vehicle___power) : string
{
	let ___parsed_power: number = (typeof ___value === 'string') ? parseFloat(___value) : ___value;
	if (isNaN(___parsed_power) === true) { return '-'; }
	switch (___convert_to) {
		case 'kw': ___parsed_power = ___parsed_power * 0.73549875; break;
		case 'hp': ___parsed_power = ___parsed_power * 1.35962162; break;
		default: return '-'; break;
	}
	const ___parsed_power___formatted: string = this.format___number(___parsed_power, 0);
	return ___parsed_power___formatted as string;
}

//#endregion

//#region 																							C O N V E R T   P O W E R   N U M B E R

	public convert___power___to_number(___value: number, ___convert_to: type___vehicle___power) : number
	{
		let ___parsed_power: number = ___value;
		switch (___convert_to) {
			case 'kw': ___parsed_power = ___parsed_power * 0.73549875; break;
			case 'hp': ___parsed_power = ___parsed_power * 1.35962162; break;
		}
		return ___parsed_power as number;
	}

//#endregion

//#region 																							F O R M A T   N U M B E R

	private format___number(___value: number, ___decimal: number) : string
	{
		const ___value___formatter: Intl.NumberFormat = new Intl.NumberFormat('de-DE', {
			minimumFractionDigits: ___decimal,
			maximumFractionDigits: ___decimal,
		});
		return ___value___formatter.format(___value) as string;
	}

//#endregion

//#region 																							F O R M A T   P R I C E

	public format___price(___value: string | number, ___symbol: string | null = '€') : string
	{
		const ___parsed_price: number = (typeof ___value === 'string') ? parseFloat(___value) : ___value;
		if (isNaN(___parsed_price) === true) { return '0'; }
		const ___parsed_price___formatted: string = this.format___number(___parsed_price, 2);
		return ___parsed_price___formatted as string + ' ' + ((___symbol !== null) ? ___symbol : '');
	}

//#endregion

//#region 																							F O R M A T   M I L E A G E

	public format___mileage(___value: string | number) : string
	{
		const ___parsed_mileage: number = (typeof ___value === 'string') ? parseFloat(___value) : ___value;
		if (isNaN(___parsed_mileage) === true) { return '0'; }
		const ___parsed_mileage___formatted: string = this.format___number(___parsed_mileage, 0);
		return ___parsed_mileage___formatted as string;
	}

//#endregion











}