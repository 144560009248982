import React from 'react'
import { IonCard } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___ComPoiEmergencyComponent } from './com-poi-emergency.locale';

//	S T Y L E

import './com-poi-emergency.scss'

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class ComPoiEmergencyComponent extends React.Component<{}, {}>
{

//#region 																							C O N S T R U C T O R
		
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonCard className="com-poi-emergency---component" color="danger">
				<h6>{ this._LOCALE.translate(locale___ComPoiEmergencyComponent.row_1) }</h6>
				<h4>{ this._LOCALE.translate(locale___ComPoiEmergencyComponent.row_2, ['112']) }</h4>
			</IonCard>
		</>;
	}

//#endregion

}