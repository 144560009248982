import React from 'react'

//	L O C A L I Z A T I O N

import { locale___ComEventDisclaimerViewComponent } from './com-event-disclaimer-view.locale';

//	S T Y L E

import './com-event-disclaimer-view.scss'

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class ComEventDisclaimerViewComponent extends React.Component<{}, {}>
{

//#region 																							C O N S T R U C T O R
		
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<section className="com-event-disclaimer-view---component">
				{ this._LOCALE.translate(locale___ComEventDisclaimerViewComponent.disclaimer) }
			</section>
		</>;
	}

//#endregion

}