export const locale___App = {

//	A C T I O N   S H E E T

	'ionActionSheet___button___cancel': {
		de_de: 'Stornieren',
		en_us: 'Cancel',
		es_es: 'Cancelar',
		fr_fr: 'Annuler',
		it_it: 'Annulla',
	},

//	A L E R T S   B U T T O N S

	'ionAlert___button___yes': {
		de_de: 'Ja',
		en_us: 'Yes',
		es_es: 'Sí',
		fr_fr: 'Oui',
		it_it: 'Si'
	},
	'ionAlert___button___cancel': {
		de_de: 'Stornieren',
		en_us: 'Cancel',
		es_es: 'Cancelar',
		fr_fr: 'Annuler',
		it_it: 'Annulla',
	},

//	P A G E S

	'ionBackButton___text': {
		de_de: 'zurück',
		en_us: 'back',
		es_es: 'volver',
		fr_fr: 'revenir',
		it_it: 'indietro',
	},

//	M O D A L S

	'modal___topBar___button___cancel': {
		de_de: 'Stornieren',
		en_us: 'Cancel',
		es_es: 'Cancelar',
		fr_fr: 'Annuler',
		it_it: 'Annulla',
	},
	'modal___topBar___button___close': {
		de_de: 'Schließen',
		en_us: 'Close',
		es_es: 'Cerrar',
		fr_fr: 'Fermer',
		it_it: 'Chiudi'
	},

//	T E S T 

	'__________________': {
		de_de: '__________________',
		en_us: '__________________',
		es_es: '__________________',
		fr_fr: '__________________',
		it_it: '__________________',
	},
	
};