import React from 'react';

//	S T Y L E

import './com-list-separator.scss'

//	E X P O R T

export default class ComListSeparatorComponent extends React.Component<{}, {}>
{

//#region 																							R E T U R N

	render(): React.ReactNode
	{
		return <>
			<div className="com-list-separator---container"></div>
		</>;
	}

//#endregion

}