import React from 'react'
import { IonCard } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___ComEventComponent } from './com-event.locale';

//	S T Y L E

import './com-event.scss'

//	S T A T E S   -   P R O P S

import { props___ComEventComponent } from './com-event.state';

//	F U N C S

import { funcs_datetime } from '../../funcs/funcs.datetime';
import { funcs_numbers } from '../../funcs/funcs.numbers';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class ComEventComponent extends React.Component<props___ComEventComponent, {}>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();
	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			{(this.props) ? <>
				{(() => { switch (this.props.size) {
					case 'large': return <>
						<section className="com-event-component---large">
							{(this.props.event_props['event_poster']) ? <>
								<img src={ this.props.event_props['event_poster'] }/>
							</> : <>
								<img src="" alt="" />
							</>}
							<h5>
								<span>{ this.props.event_props['event_name'] }</span>
							</h5>
							{(this.props.event_props['event_is_now'] === true) ? <>
								<p className="com-event---is-now">
									<span>{ this._LOCALE.translate(locale___ComEventComponent.event_is_now, [this.funcs___numbers.number___format_as_string___distance(this.props.event_props['event_coords_distance'])]) }</span>
								</p>
							</> : <>
								<p>
									<span>{ this.funcs___datetime.datetime___get___date(this.props.event_props['event_datetime_begin']) }</span>
									<span> { this._LOCALE.translate(locale___ComEventComponent.event_begin, [this.funcs___datetime.datetime___get___time(this.props.event_props['event_datetime_begin'])]) }</span>
								</p>
							</>}
						</section>
					</>; break;
					case 'normal': return <>
						<IonCard className={ 'com-event-component---medium ' + ((this.props.event_props['event_ended'] === true) ? ' com-event---is-finished ' : '')}>
							{(this.props.event_props['event_poster']) ? <>
								<img src={ this.props.event_props['event_poster'] }/>
							</> : <>
								<img src="" alt="" />
							</>}
							<section>
								{/*	
									<span *ngIf="this.props.event___props['poi_verified'] === true" className="chip---verified">
										<i className="fas fa-check"></i>
										<span>Proprietario verificato</span>
									</span>
								*/}
								<h5>
									<span>{ this.props.event_props['event_name'] }</span>
								</h5>
								{(this.props.event_props['event_ended'] === true) ? <>
									<p>
										<i className="fas fa-calendar-alt"></i>
										<span>{ this._LOCALE.translate(locale___ComEventComponent.event_ended) }</span>
									</p>
								</> : <>
									{(this.props.event_props['event_is_now'] === true) ? <>
										<p className="com-event---is-now">
											<span>{ this._LOCALE.translate(locale___ComEventComponent.event_is_now, [this.funcs___numbers.number___format_as_string___distance(this.props.event_props['event_coords_distance'])]) }</span>
										</p>
									</> : <>
										<p>
											<i className="fas fa-calendar-alt"></i>
											<span>{ this.funcs___datetime.datetime___get___date(this.props.event_props['event_datetime_begin']) }</span>
											<span> { this._LOCALE.translate(locale___ComEventComponent.event_begin, [this.funcs___datetime.datetime___get___time(this.props.event_props['event_datetime_begin'])]) }</span>
										</p>
										<p>
											<i className="fas fa-map-marked-alt"></i>
											<span>{ this._LOCALE.translate(locale___ComEventComponent.far_from_you, [this.funcs___numbers.number___format_as_string___distance(this.props.event_props['event_coords_distance'])]) }</span>
										</p>
									</>}
								</>}
								
							</section>
						</IonCard>
					</>; break;
				}})()}
			</> : <></>}
		</>;
	}

//#endregion

}