import React from 'react'
import { IonContent, IonPopover } from '@ionic/react';
import { Link } from 'react-router-dom';

//	L O C A L I Z A T I O N

import { locale___ComPoiVerifiedComponent } from './com-poi-verified.locale';

//	S T Y L E

import './com-poi-verified.scss'

//	S T A T E   -   P R O P S

import { props___ComPoiVerifiedComponent } from './com-poi-verified.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class ComPoiVerifiedComponent extends React.Component<props___ComPoiVerifiedComponent, {}>
{

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div id="com-poi-verified---container" className="com-poi-verified---container">
				<img src="/assets/ui/icons/icon-poi-verified.svg"/>
			</div>
			{(this.props.popup && this.props.popup === true) ? <>
				<IonPopover className="com-poi-verified---container---popover" trigger="com-poi-verified---container" triggerAction="click">
					<IonContent forceOverscroll={ false }>
						<section>
							<p>{ this._LOCALE.translate(locale___ComPoiVerifiedComponent.poi_verified_popup) }</p>
							<Link to="/info/faq/verification">{ this._LOCALE.translate(locale___ComPoiVerifiedComponent.poi_verified_popup_link) }</Link>
						</section>
					</IonContent>
				</IonPopover>
			</> : <></>}
		</>;
	}

//#endregion

}