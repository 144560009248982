export const locale___SbInfoCopyrightPage = {
	'motto_title': {
		de_de: 'Entdecken Sie die Welt um Sie herum',
		en_us: 'discover the world around you',
		es_es: 'descubre el mundo que te rodea',
		fr_fr: 'découvrez le monde qui vous entoure',
		it_it: 'scopri il mondo intorno a te',
	},
	'motto_description': {
		de_de: 'nearby ist die einzige Plattform, mit der Sie Orte erkunden und mehr über die Aktivitäten der Welt um Sie herum erfahren können',
		en_us: 'nearby is the only platform that lets you explore places and learn about the activities of the world around you',
		es_es: 'nearby es la única plataforma que te permite explorar lugares y aprender sobre las actividades del mundo que te rodea',
		fr_fr: 'nearby est la seule plateforme qui vous permet d\'explorer des lieux et de découvrir les activités du monde qui vous entoure',
		it_it: 'nearby è l\'unica piattaforma che ti fa esplorare i luoghi e conoscere le attività del mondo che ti circonda',
	},
	'copyright_extended_notice': {
		de_de: 'Die App, die Website und die damit verbundenen Inhalte werden von Prysm Lab konzipiert, entworfen, entwickelt, verwaltet und verwaltet. Der eingetragene Sitz befindet sich in Terralba (Or), in der Via Azuni, civ. 27, Postleitzahl 09098, mit digitaler Adresse prysmlab@pec.it, REA-Nummer OR-139267, D-U-N-S®-Nummer 438059184, Steuernummer und Registrierungsnummer im Oristano Company Register 01212540957',
		en_us: 'The app, website, and related content are conceived, designed, developed, managed, and administered by Prysm Lab, whose registered office is located in Terralba (Or), at Via Azuni, no. 27, ZIP code 09098, with digital domicile prysmlab@pec.it, REA number OR-139267, D-U-N-S® Number 438059184, tax code and registration number in the Oristano Business Register 01212540957.',
		es_es: 'La aplicación, el sitio web y los contenidos relacionados son concebidos, diseñados, desarrollados, gestionados y administrados por Prysm Lab, cuyo domicilio social se encuentra en Terralba (Or), en via Azuni, civ. 27, código postal 09098, con dirección digital prysmlab@pec.it, número REA OR-139267, número D-U-N-S® 438059184, código fiscal y número de inscripción en el Registro Mercantil de Oristano 01212540957',
		fr_fr: 'L\'application, le site Web et les contenus associés sont conçus, conçus, développés, gérés et administrés par Prysm Lab, dont le siège social est situé à Terralba (Or), via Azuni, civ. 27, code postal 09098, avec adresse numérique prysmlab@pec.it, numéro REA OR-139267, numéro D-U-N-S® 438059184, code fiscal et numéro d\'inscription au registre des entreprises d\'Oristano 01212540957',
		it_it: 'L\'app, il sito web ed i relativi contenuti sono ideati, progettati, sviluppati, gestiti ed amministrati da Prysm Lab, la cui sede legale è situata in Terralba (Or), alla via Azuni, civ. 27, CAP 09098, con domicilio digitale prysmlab@pec.it, numero REA OR-139267, D-U-N-S® Number 438059184, codice fiscale e numero di iscrizione al Registro delle Imprese di Oristano 01212540957',
	},
};