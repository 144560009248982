import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonRefresher, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';
import { Link } from 'react-router-dom';

//	L O C A L I Z A T I O N

import { locale___App } from '../../App.locale';
import { locale___SbEventsCategoryViewPage } from './sb-events-category-view.locale';

//	S T Y L E

import './sb-events-category-view.scss';

//	S T A T E   -   P R O P S

import { props___SbEventsCategoryViewPage, state___SbEventsCategoryViewPage, state___SbEventsCategoryViewPage___default } from './sb-events-category-view.state';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';
import { service_RestApiService } from '../../services/service-api';
import { service_Router } from '../../services/service-router';

//	C O M P O N E N T S

import ComEventComponent from '../../components/com-event/com-event';
import ComGalleryPlaceholderComponent from '../../components/com-gallery-placeholder/com-gallery-placeholder';
import ComInfiniteScrollerComponent from '../../components/com-infinite-scroller/com-infinite-scroller';
import ComListEmptyComponent from '../../components/com-list-empty/com-list-empty';
import ComListSeparatorComponent from '../../components/com-list-separator/com-list-separator';
import ComSpinnerComponent from '../../components/com-spinner/com-spinner';
import ComSpinnerRefresherComponent from '../../components/com-spinner-refresher/com-spinner-refresher';

//	C L A S S

class SbEventsCategoryViewPage extends React.Component<props___SbEventsCategoryViewPage, state___SbEventsCategoryViewPage> {

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _ROUTER: service_Router = new service_Router();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbEventsCategoryViewPage,
	) {
		super(props);
		this.state = state___SbEventsCategoryViewPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___events_category = () => {
		this.setState({
			events_category___events_collection: [],
			events_category___events_collection___isLoading: true,
			events_category___events_collection___is_complete: false,
		}, async () => {
			if (this.state.events_category___events_collection___target === 'all') {
				this.setState({
					events_category___props: null,
					events_category___props___isLoading: false,
				}, async () => {
					await this.read_props___events_category___events_collection();
				});
			} else {
				const ___temp___events_category___props: type___api___response = await this._API.events_categories___read___single(this.state.events_category___id);
				if (!___temp___events_category___props || ___temp___events_category___props.response !== 'success' || ___temp___events_category___props.data === null) { console.log('404'); }
				this.setState({
					events_category___props: ___temp___events_category___props.data,
					events_category___props___isLoading: false,
				}, async () => {
					await this.read_props___events_category___events_collection();
				});
			}
		});
	}

	private read_props___events_category___events_collection = async () => {
		const ___temp___events_collection___props: type___api___response = await this._API.events___read___multi(this.state.events_category___events_collection___target, this.state.events_category___id, this.state.events_category___events_collection.length);
		this.setState((prevState) => ({
			events_category___events_collection: [...prevState.events_category___events_collection, ...___temp___events_collection___props.data],
			events_category___events_collection___isLoading: false,
			events_category___events_collection___is_complete: (___temp___events_collection___props.response === 'no-data') ? true : false,
		}));
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___page___refresh = (___e: CustomEvent<RefresherEventDetail>) => {
		this.read_props___events_category();
		___e.detail.complete();
	}

	private handle___page___load_more = () => {
		this.setState({
			events_category___events_collection___isLoading: true
		}, async () => {
			await this.read_props___events_category___events_collection();
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		const ___events_category_id: string | null = this._ROUTER.get_id(this.props);
		if (___events_category_id) {
			this.setState({
				events_category___id: (___events_category_id === 'all') ? null : ___events_category_id,
				events_category___events_collection___target: (___events_category_id === 'all') ? 'all' : 'category',
			}, () => {
				this.read_props___events_category();
			});
		}
	}

	ionViewWillLeave() : void {
		this.setState({
			...state___SbEventsCategoryViewPage___default
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover" text={ this._LOCALE.translate(locale___App.ionBackButton___text) } />
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
						<ComSpinnerRefresherComponent/>
					</IonRefresher>

					{(this.state.events_category___props___isLoading === true) ? <>
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<ComSpinnerComponent size="small"/>
					</> : <>
						<div className="sb-page---container sb-events-category-view---container">

							<section className="sb-events-category-view---category-image">
								{(() => { switch (this.state.events_category___events_collection___target) {
									case 'all': return <>
										<ComGalleryPlaceholderComponent placeholder_path={ '/assets/markers/event.svg' }/>
									</>; break;
									default: return <>
										<ComGalleryPlaceholderComponent placeholder_path={ '/assets/markers/events/' + this.state.events_category___props['event_category_icon'] + '.svg' }/>
									</>; break;
								}})()}
							</section>

							<section className="sb-events-category-view---category-box">
								{(() => { switch (this.state.events_category___events_collection___target) {
									case 'all': return <>
										<p>{ this._LOCALE.translate(locale___SbEventsCategoryViewPage.category_event) }</p>
										<h3>{ this._LOCALE.translate(locale___SbEventsCategoryViewPage.category_event___all) }</h3>
									</>; break;
									default: return <>
										<p>{ this._LOCALE.translate(locale___SbEventsCategoryViewPage.category_event) }</p>
										<h3>{ this.state.events_category___props['event_category_name'] }</h3>
									</>; break;
								}})()}
							</section>

							<section className="sb-events-category-view---category-scroller">
								{this.state.events_category___events_collection.map((___e: any, ___e___idx: number) => {
									return <>
										<Link key={ ___e___idx } to={ '/view/events/' + ___e['event_id'] }>
											<ComEventComponent event_props={ ___e } size="normal"/>
										</Link>
										<ComListSeparatorComponent />
									</>;
								})}
							</section>

							{(this.state.events_category___events_collection___is_complete === true) ? <>
								<ComListEmptyComponent list_items_quantity={ this.state.events_category___events_collection.length }/>
							</> : <>
								<ComInfiniteScrollerComponent is_loading={ this.state.events_category___events_collection___isLoading } event___scrollTriggered={ this.handle___page___load_more } />
							</>}

						</div>
					</>}

				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbEventsCategoryViewPage);