export const locale___SbInfoSupportPage = {
	'support___title': {
		de_de: 'Hilfe und Support',
		en_us: 'Assistance and Support',
		es_es: 'Asistencia y Soporte',
		fr_fr: 'Assistance et Soutien',
		it_it: 'Assistenza e Supporto',
	},
	'support___paragraph': {
		de_de: 'Wenn Sie Probleme mit der App haben, Hilfe benötigen oder Fehlverhalten oder Inhalte melden möchten, die gegen die Nutzungsbedingungen verstoßen, zögern Sie nicht, uns zu kontaktieren',
		en_us: 'If you encounter problems with the app, if you need assistance or if you want to report incorrect behavior or content that violates the terms and conditions, do not hesitate to contact us',
		es_es: 'Si tiene problemas con la aplicación, si necesita ayuda o si desea informar un comportamiento incorrecto o contenido que viole los términos y condiciones, no dude en contactarnos',
		fr_fr: 'Si vous rencontrez des problèmes avec l\'application, si vous avez besoin d\'aide ou si vous souhaitez signaler un comportement incorrect ou un contenu qui enfreint les termes et conditions, n\'hésitez pas à nous contacter',
		it_it: 'Se riscontri problemi con l\'app, se hai bisogno di assistenza o se vuoi segnalare un comportamento scorretto o dei contenuti che violano i termini e le condizioni non esitare a contattarci',
	},
	'socials___title': {
		de_de: 'Unsere Sozialen Netzwerke',
		en_us: 'Our Social Networks',
		es_es: 'Nuestras Redes Sociales',
		fr_fr: 'Nos Réseaux Sociaux',
		it_it: 'I nostri Social',
	},










	





};