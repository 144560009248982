export const locale___SbDiscoverMapPage = {
	'search_something': {
		de_de: 'Nach etwas suchen...',
		en_us: 'Search something...',
		es_es: 'Busca algo...',
		fr_fr: 'Rechercher quelque chose...',
		it_it: 'Cerca qualcosa...',
	},
	'your_position': {
		de_de: 'Ihr Standort',
		en_us: 'Your location',
		es_es: 'Tu ubicación',
		fr_fr: 'Votre emplacement',
		it_it: 'La tua posizione',
	},
};