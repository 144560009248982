import { interface___modal, interface___modal___dismissible } from "../../interfaces/interface.modals";

//	P R O P S

export interface props___MdEntryLanguageModal extends interface___modal, interface___modal___dismissible {

}

//	S T A T E

export interface state___MdEntryLanguageModal {

	gui_timeout___text_elements___index: number,

}

//	S T A T E   D E F A U L T

export const state___MdEntryLanguageModal___default: state___MdEntryLanguageModal = {

	gui_timeout___text_elements___index: 0,

}