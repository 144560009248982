import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonList, IonPage, IonRefresher, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';
import { Link } from 'react-router-dom';

//	L O C A L I Z A T I O N

import { locale___App } from '../../../App.locale';
import { locale___SbPoiViewDigitalMenuPage } from './sb-poi-view-digitalmenu.locale';

//	S T Y L E

import './sb-poi-view-digitalmenu.scss';

//	S T A T E   -   P R O P S

import { props___SbPoiViewDigitalMenuPage, state___SbPoiViewDigitalMenuPage, state___SbPoiViewDigitalMenuPage___default } from './sb-poi-view-digitalmenu.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';
import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';

//	C O M P O N E N T S

import Com404Component from '../../../components/com-404/com-404';
import ComDigitalMenuDishComponent from '../../../components/com-digitalmenu-dish/com-digitalmenu-dish';
import ComPoiComponent from '../../../components/com-poi/com-poi';
import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';
import ComSpinnerInlineComponent from '../../../components/com-spinner-inline/com-spinner-inline';

//	M O D A L S

import MdDigitalMenuDishModal from '../../../modals/md-digitalmenu-dish-view/md-digitalmenu-dish-view';

//	C L A S S

class SbPoiViewDigitalMenuPage extends React.Component<props___SbPoiViewDigitalMenuPage, state___SbPoiViewDigitalMenuPage> {

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _ROUTER: service_Router = new service_Router();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbPoiViewDigitalMenuPage,
	) {
		super(props);
		this.state = state___SbPoiViewDigitalMenuPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___poi = async () => {
		const ___temp___poi___props: type___api___response = await this._API.poi___read___single(this.state.poi___id);
		if (!___temp___poi___props || ___temp___poi___props.response !== 'success' || ___temp___poi___props.data === null) { console.log('404'); }
		this.setState({
			poi___props: ___temp___poi___props.data,
			poi___props___isLoading: false,
		}, async () => {
			await this.read_props___poi_digitalmenu();
		});
	}

	read_props___poi_digitalmenu = async () => {
		const ___temp___poi_digitalmenu: type___api___response = await this._API.digitalmenus___read___single('poi', this.state.poi___props['poi_id']);
		this.setState({
			poi_digitalmenu___props: ___temp___poi_digitalmenu.data,
			poi_digitalmenu___props___isLoading: false,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	handle___page___refresh = (___e: CustomEvent<RefresherEventDetail>) => {
		this.setState({
			poi___props: null,
			poi___props___isLoading: true,
		}, async () => {
			await this.read_props___poi();
			___e.detail.complete();
		});
	}

	handle___dish_view = (___dish_target: string) => {
		this.setState({
			ionModal___MdDigitalMenuDishModal___isOpen: true,
			ionModal___MdDigitalMenuDishModal___target: ___dish_target,
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		const ___poi_id: string | null = this._ROUTER.get_id(this.props);
		if (___poi_id) {
			this.setState({
				poi___id: ___poi_id,
			}, async () => {
				await this.read_props___poi();
			});
		}
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<MdDigitalMenuDishModal isOpen={ this.state.ionModal___MdDigitalMenuDishModal___isOpen } dish_id={ this.state.ionModal___MdDigitalMenuDishModal___target } event___onDidDismiss={() => { this.setState({ ionModal___MdDigitalMenuDishModal___isOpen: false, ionModal___MdDigitalMenuDishModal___target: null }); }}/>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref={ '/view/poi/' + this.state.poi___id } text={ this._LOCALE.translate(locale___App.ionBackButton___text) }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>
					{(this.state.poi___props___isLoading === true) ? <>

						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<ComSpinnerComponent size="small"/>

					</> : <>

						<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
							<ComSpinnerInlineComponent/>
						</IonRefresher>

						{(this.state.poi___props !== null) ? <>
							<div className="sb-page---container sb-poi-view-digitalmenu---container">

								<ComPoiComponent poi_props={ this.state.poi___props } size='large'/>

								<br />

								{(this.state.poi_digitalmenu___props___isLoading === true) ? <>

									<br />
									<br />
									<br />
									<br />
									<ComSpinnerComponent size="small"/>

								</> : <>

									{this.state.poi_digitalmenu___props['digitalmenu_contents'].map((___d_c: any, ___d_c___idx: number) => {
										return <>
											{(___d_c['dish_category_contents'].length > 0) ? <>
												<IonCard key={ ___d_c___idx } className="sb-poi-view-digitalmenu---card">
													<IonItem className="sb-poi-view-digitalmenu---card---title">
														<div>
															<img src={ '/assets/digitalmenu/categories/' + ___d_c['dish_category_icon'] + '.svg' } />
															<h6>{ ___d_c['dish_category_name'] }</h6>
														</div>
													</IonItem>
													<IonList>
														{___d_c['dish_category_contents'].map((___dish: any, ___dish___idx: number) => {
															return <>
																<ComDigitalMenuDishComponent key={ ___dish___idx } dish_props={ ___dish } dish_icon={ ___d_c['dish_category_icon'] } event___onClick={ this.handle___dish_view }/>
															</>;
														})}
													</IonList>
												</IonCard>
												<br />
											</> : <></>}
										</>;
									})}

									<br />
									<br />

									<Link to="/info/digitalmenu/allergens">
										<span>{ this._LOCALE.translate(locale___SbPoiViewDigitalMenuPage.button___allergen_list) }</span>
										<i className="fas fa-chevron-right"></i>
									</Link>

									<br />
									<br />

								</>}

							</div>
						</> : <>
							<Com404Component/>
						</>}

					</>}
				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbPoiViewDigitalMenuPage);