import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonListHeader, IonPage, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___App } from '../../../App.locale';
import { locale___SbInfoDigitalMenuAllergensPage } from './sb-info-digitalmenu-allergens.locale';

//	S T Y L E

import './sb-info-digitalmenu-allergens.scss';

//	S T A T E   -   P R O P S

import { state___SbInfoDigitalMenuAllergensPage, state___SbInfoDigitalMenuAllergensPage___default } from './sb-info-digitalmenu-allergens.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';
import { service_RestApiService } from '../../../services/service-api';

//	C O M P O N E N T S

import ComSpinnerInlineComponent from '../../../components/com-spinner-inline/com-spinner-inline';

//	C L A S S

class SbInfoDigitalMenuAllergensPage extends React.Component<{}, state___SbInfoDigitalMenuAllergensPage>
{

//#region 																							D E C L A R A T I O N S
	
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: {},
	) {
		super(props);
		this.state = state___SbInfoDigitalMenuAllergensPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___digitalmenu_allergens = async () => {
		const ___temp___dish_allergens: type___api___response = await this._API.digitalmenus_dishes_allergens___read___multi();
		this.setState({
			allergens_collection: ___temp___dish_allergens.data,
			allergens_collection___isLoading: false,
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	async ionViewDidEnter() : Promise<void> {
		await this.read_props___digitalmenu_allergens();
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/settings" text={ this._LOCALE.translate(locale___App.ionBackButton___text) } />
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>

					<IonListHeader>
						<IonLabel>{ this._LOCALE.translate(locale___SbInfoDigitalMenuAllergensPage.title) }</IonLabel>
					</IonListHeader>

					<br />

					<div className="sb-info-digitalmenu-allergens---container">
						{(this.state.allergens_collection___isLoading === true) ? <>
							
							<br />
							<br />
							<br />
							<ComSpinnerInlineComponent/>

						</> : <>
						
							{this.state.allergens_collection.map((___a: any, ___a___idx: number) => {
								return <>
									<IonCard key={ ___a___idx } className="sb-info-digitalmenu-allergens---card">
										<IonItem className="sb-info-digitalmenu-allergens---card---title">
											<div>
												<img src={ '/assets/digitalmenu/allergens/' + ___a['dish_allergen_icon'] + '.svg' } />
												<h6>{ ___a['dish_allergen_name'] }</h6>
											</div>
										</IonItem>
										<p>{ ___a['dish_allergen_description'] }</p>
									</IonCard>
									<br />
								</>;
							})}

						</>}
					</div>

				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbInfoDigitalMenuAllergensPage);