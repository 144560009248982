import React from 'react'

//	S T Y L E

import './com-spinner.scss'

//	S T A T E S   -   P R O P S

import { props___ComSpinnerComponent } from './com-spinner.state';

//	C L A S S

export default class ComSpinnerComponent extends React.Component<props___ComSpinnerComponent, object>
{

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComSpinnerComponent,
	) {
		super(props);
	}

//#endregion

//#region 																							R E N D E R

	render(): React.ReactNode
	{
		return <>
			<div className={ 'nearby-spinner---' + this.props.size }>
				<img className="nearby-spinner---inner" src="/assets/ui/spinner/inner.svg"/>
				<img className="nearby-spinner---outer" src="/assets/ui/spinner/outer.svg"/>
			</div>
		</>;
	}

//#endregion

}