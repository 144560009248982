import React from 'react';
import { IonContent, IonPage, withIonLifeCycle } from '@ionic/react';
import { Link } from 'react-router-dom';
import mapboxgl, { GeoJSONFeature, LayerSpecification } from 'mapbox-gl';
import { debounce } from 'lodash';

//	L O C A L E

import { locale___SbDiscoverMapPage } from './sb-discover-map.locale';

//	S T Y L E

import 'mapbox-gl/dist/mapbox-gl.css';
import './sb-discover-map.scss';

//	T Y P E S

import { type___geo___lngLat, type___map___element___type } from '../../types/types.types';
import { type___api___response } from '../../types/types.api-response';

//	S T A T E   -   P R O P S

import { props___SbDiscoverMapPage, state___SbDiscoverMapPage, state___SbDiscoverMapPage___defaults } from './sb-discover-map.state';

//	L I B S

import { lib_icons } from '../../libs/lib.icons';
import { lib_names } from '../../libs/lib.names';

//	F U N C S

import { funcs_mapbox } from '../../funcs/funcs.mapbox';

//	S E R V I C E S

import { service_GeolocationService } from '../../services/service-geolocation';
import { service_LocalizationService } from '../../services/service-localization';
import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComDiscoverElementComponent from '../../components/com-discover-element/com-discover-element';
import ComHeaderLogoComponent from '../../components/com-header-logo/com-header-logo';
import ComSpinnerTagComponent from '../../components/com-spinner-tag/com-spinner-tag';

//	M O D A L S

import MdDiscoverSearchPreviewModal from '../../modals/md-discover-search-preview/md-discover-search-preview';
import MdDiscoverSearchModal from '../../modals/md-discover-search/md-discover-search';
import MdDiscoverCompassComponent from '../../modals/md-discover-compass/md-discover-compass';

//	C L A S S

class SbDiscoverMapPage extends React.Component<props___SbDiscoverMapPage, state___SbDiscoverMapPage>
{

//#region 																							D E C L A R A T I O N S

	private _ABORT___CONTROLLER: AbortController | null = null;
	private _ABORT___SIGNAL: AbortSignal | null = null;

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _GEOLOCATION: service_GeolocationService = new service_GeolocationService();

	private readonly funcs___mapbox: funcs_mapbox = new funcs_mapbox();

	private me___geolocation: type___geo___lngLat = { lng: 0, lat: 0 };
	private me___marker: mapboxgl.Marker;

	private readonly mapbox___map___container: React.RefObject<HTMLDivElement> = React.createRef();

	private mapbox___map___isLoading: boolean = true;
	private mapbox___map___isLoading___icons: boolean = true;

	private mapbox___map: mapboxgl.Map;
	private mapbox___map___center___lng: number = 0;
	private mapbox___map___center___lat: number = 0;
	private mapbox___map___onClickElement: boolean = false;
	private mapbox___map___onMoveEnd: Promise<any>[] = [];
	
	private readonly mapbox___map___collection___source_layer_default___type: LayerSpecification['type'] = 'symbol';
	private readonly mapbox___map___collection___source_layer_default___layout: any = { 'visibility': 'none', 'icon-image': [ 'case', ['!=', ['get', 'marker_icon_custom'], 'null'], ['get', 'marker_icon_custom'], ['get', 'marker_icon']], 'icon-size': [ 'interpolate', ['linear'], ['zoom'], 7.5, 0.000, 8, 0.075, 12, 0.275, 18, 0.55 ]};

	private mapbox___map___layer_collection: string[] = [];
	private readonly mapbox___map___layer_constructor: GeoJSON.FeatureCollection<GeoJSON.Geometry> = { 'type': 'FeatureCollection', 'features': [] };

	private mapbox___map___collection_events: GeoJSON.FeatureCollection<GeoJSON.Geometry> = this.mapbox___map___layer_constructor;
	private mapbox___map___collection_events___ids: string[] = [];
	private readonly mapbox___map___collection_events___source_name: string = 'source-events';
	private readonly mapbox___map___collection_events___source_layer: string = 'source-events-layer';

	private mapbox___map___collection_poi: GeoJSON.FeatureCollection<GeoJSON.Geometry> = this.mapbox___map___layer_constructor;
	private mapbox___map___collection_poi___ids: string[] = [];
	private readonly mapbox___map___collection_poi___source_name: string = 'source-poi';
	private readonly mapbox___map___collection_poi___source_layer: string = 'source-poi-layer';

	private mapbox___map___collection_tales: any[] = [];
	private mapbox___map___collection_tales___ids: string[] = [];
	private mapbox___map___collection_tales___markers: mapboxgl.Marker[] = [];

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbDiscoverMapPage,
	) {

		super(props);
		this.state = state___SbDiscoverMapPage___defaults;
		
		this.constructor___debounce();
		
		this.mapbox___map___container = React.createRef();

		this.mapbox___map___collection_events = this.mapbox___map___layer_constructor;
		this.mapbox___map___collection_poi = this.mapbox___map___layer_constructor;

		this.mapbox___map___layer_collection = [ this.mapbox___map___collection_events___source_layer, this.mapbox___map___collection_poi___source_layer ];

	}

	constructor___debounce() : void {

		this.handle___discover___search___input = this.handle___discover___search___input.bind(this);
		this.handle___discover___search = debounce(this.handle___discover___search.bind(this), lib_names.lodash.debounce_delay);

		this.map___load___datas___input = this.map___load___datas___input.bind(this);
		this.map___load___datas = debounce(this.map___load___datas.bind(this), lib_names.lodash.debounce_delay);

	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___search___locations = async () => {
		const ___search_results___cities: type___api___response = await this._API.search___locations('cities', this.state.discover___search___param, 3)
		this.setState({
			discover___search_cities: ___search_results___cities.data,
			discover___search_cities___isLoading: false,
		});
	}

	private read_props___search___poi = async () => {
		const ___search_results___temp: type___api___response = await this._API.search___discover(this.state.discover___search___param);
		this.setState({
			discover___search_poi: ___search_results___temp.data,
			discover___search_poi___isLoading: false,
		});
	}

//#endregion

//#region 																							M A P

	private map___get_center = async () => {
		const ___map_center___coords: type___geo___lngLat | null = await this._GEOLOCATION.geolocation___locate();
		this.mapbox___map___center___lat = ___map_center___coords.lat ?? 39;
		this.mapbox___map___center___lng = ___map_center___coords.lng ?? 9;
		this.me___geolocation = ___map_center___coords;
		return;
	}

	private map___get___watch_position = () => {
		navigator.geolocation.watchPosition(
			(___p: GeolocationPosition) => {
				const ___watch_coords___lng: number = ___p.coords.longitude;
				const ___watch_coords___lat: number = ___p.coords.latitude;
				this.me___geolocation = { lng: ___watch_coords___lng, lat: ___watch_coords___lat};
				this.me___marker.setLngLat([this.me___geolocation.lng, this.me___geolocation.lat]);
			},
			(___e: any) => {
				console.log('Error Following Position   -->   ', ___e);
			}
		);
	}

	private map___prepare = () => {
		this.setState({
			map___isLoading___events: true,
			map___isLoading___poi: true,
		}, () => {
			if (this.mapbox___map___isLoading === true) {

				this.mapbox___map = new mapboxgl.Map({
					accessToken: lib_names.mapboxgl.accessToken,
					container: 'sb-discover-map---map', //	this.mapbox___map___container.current as HTMLElement,
					style: lib_names.mapboxgl.style_normal,
					center: [this.mapbox___map___center___lng, this.mapbox___map___center___lat],
					zoom: 1,
					minZoom: 1,
					maxZoom: lib_names.mapboxgl.maxZoom,
					dragRotate: lib_names.mapboxgl.dragRotate,
					doubleClickZoom: lib_names.mapboxgl.doubleClickZoom,
					touchPitch: lib_names.mapboxgl.touchPitch,
					maxPitch: lib_names.mapboxgl.maxPitch,
				});

				this.mapbox___map.resize();
				this.mapbox___map___isLoading = false;

				this.mapbox___map.on('load', () => {

					this.map___get___watch_position();

					this.mapbox___map___isLoading = false;

					if (this.mapbox___map___isLoading___icons === true) {
						lib_icons.events.map((___i_e: string) => this.funcs___mapbox.map___load___icon(this.mapbox___map, ___i_e, 'events'));
						lib_icons.poi.map((___i_p: string) => this.funcs___mapbox.map___load___icon(this.mapbox___map, ___i_p, 'poi'));
						this.mapbox___map___isLoading___icons = false;
					}

					const ___me___marker___circle: HTMLDivElement = document.createElement('div');
					___me___marker___circle.className = 'sb-discover-map---me---marker';
					const ___me___marker___circle___popup___html: string = '<h3 class="sb-discover-map---me---popup">' + this._LOCALE.translate(locale___SbDiscoverMapPage.your_position) + '</h3>';
					const ___me___marker___circle___popup: mapboxgl.Popup = new mapboxgl.Popup({ offset: 25 }).setHTML(___me___marker___circle___popup___html);
					this.me___marker = new mapboxgl.Marker(___me___marker___circle).setLngLat([this.me___geolocation.lng, this.me___geolocation.lat]).addTo(this.mapbox___map);
					this.me___marker.setPopup(___me___marker___circle___popup);

					this.mapbox___map.resize();
					this.map___fly___to(this.mapbox___map___center___lng, this.mapbox___map___center___lat);

					this.map___addSource___events();
					this.map___addSource___poi();
					
					(async () => {
						await this.map___load___datas(true);
						return;
					})();

			//		setTimeout(() => {
			//			this.mapbox___map.setMinZoom(lib_names.mapboxgl.minZoom);
			//		}, 2500);

				});

				this.mapbox___map.on('preclick', () => {
					this.setState({
						map___element_pane: 'navigation',
					});
				});

				this.mapbox___map.on('moveend', async () => {
					const ___mapbox___map___center: mapboxgl.LngLat = this.mapbox___map.getCenter();
					this.mapbox___map___center___lng = ___mapbox___map___center.lng;
					this.mapbox___map___center___lat = ___mapbox___map___center.lat;
					await this.map___load___datas();
				});

	/*

				const ___center_marker___poi = document.createElement('div');
				___center_marker___poi.className = 'map-marker---user';
				const ___center_marker___popup___html = '<section style="display:flex;margin-top:0;margin-bottom:-5pt;padding-right:15pt;padding-left:15pt;">' + 
															'<span style="height:25pt;line-height:25pt;font-size:10pt;font-weight:bold;">' + 'sb-sb-discover-map.ts.___your_position' + '</span>' +
														'</section>';

				const ___center_marker___coords: mapboxgl.LngLatLike = [ this.mapbox___map___center___lng, this.mapbox___map___center___lat ];
				const ___center_marker___popup = new mapboxgl.Popup().setHTML(___center_marker___popup___html);

				this.me___marker = new mapboxgl.Marker(___center_marker___poi, { offset: [0, 0]})
					.setLngLat(___center_marker___coords)
					.setPopup(___center_marker___popup)
					.addTo(this.mapbox___map);

			//	this.mapbox___map_marker___user_position.getElement()
			//		.addEventListener('click', () => {
			//			this.map___fly___to(this.mapbox___map___center___lng, this.mapbox___map___center___lat);
			//		});
	*/

			}
		});
	}

	private map___fly___to = async (___lng: number, ___lat: number, ___zoom: number = 18) => {
		this.mapbox___map___center___lng = ___lng;
		this.mapbox___map___center___lat = ___lat;
		this.mapbox___map.flyTo({ center: [ this.mapbox___map___center___lng, this.mapbox___map___center___lat], zoom: ___zoom });
		await this.map___load___datas();
	}

	private map___load___datas___input = async (___ignore_zoom: boolean = false) => {
		await this.map___load___datas(___ignore_zoom);
	}

	private map___load___datas = async (___ignore_zoom: boolean = false) => {
		this.setState({
			map___isLoading___events: true,
			map___isLoading___poi: true,
		});
		const ___map_zoom: number = this.mapbox___map.getZoom();
		if (___map_zoom <= lib_names.mapboxgl.maxZoom || ___ignore_zoom === true) {
			await Promise.all([
				this.map___read_props___collection_poi(),
				this.map___read_props___collection_events(),
			]);
		}
	}

	private map___addSource___events = () => {
		this.mapbox___map.addSource(this.mapbox___map___collection_events___source_name, {
			type: 'geojson',
			data: this.mapbox___map___collection_events
		});
		this.mapbox___map.addLayer({
			'minzoom': lib_names.mapboxgl.minZoomToDiscover,
			'id': this.mapbox___map___collection_events___source_layer,
			'type': this.mapbox___map___collection___source_layer_default___type,
			'source': this.mapbox___map___collection_events___source_name,
			'layout': this.mapbox___map___collection___source_layer_default___layout
		});
	}

	private map___addSource___poi = () => {
		this.mapbox___map.addSource(this.mapbox___map___collection_poi___source_name, {
			type: 'geojson',
			data: this.mapbox___map___collection_poi
		});
		this.mapbox___map.addLayer({
			'minzoom': lib_names.mapboxgl.minZoomToDiscover,
			'id': this.mapbox___map___collection_poi___source_layer,
			'type': this.mapbox___map___collection___source_layer_default___type,
			'source': this.mapbox___map___collection_poi___source_name,
			'layout': this.mapbox___map___collection___source_layer_default___layout,
		});
	}

	private map___read_props___collection_events = async () => {
		const ___map_events___collection: type___api___response = await this._API.map___read___events([ this.mapbox___map___center___lng, this.mapbox___map___center___lat ], this.mapbox___map___collection_events___ids, this._ABORT___SIGNAL);
		if (___map_events___collection['response'] !== 'error') {
			this.funcs___mapbox.map___load___icon___customs(this.mapbox___map, this.mapbox___map___collection_events['features']);
			this.mapbox___map___collection_events['features'] = [...this.mapbox___map___collection_events['features'], ...___map_events___collection['data']['features']];
			this.mapbox___map___collection_events___ids = this.mapbox___map___collection_events['features'].map((___f: any) => { return ___f['properties']['marker_id']; })
			if (this.mapbox___map.getSource(this.mapbox___map___collection_events___source_name)) {
				(this.mapbox___map.getSource(this.mapbox___map___collection_events___source_name) as mapboxgl.GeoJSONSource)
					.setData(this.mapbox___map___collection_events);
			} else {
				this.map___addSource___events();
			}
			this.mapbox___map.setLayoutProperty(this.mapbox___map___collection_events___source_layer, 'visibility', 'visible');
			this.handle___map___onClickElement();
		}
		this.setState({
			map___isLoading___events: false,
		});
	}

	private map___read_props___collection_poi = async () => {
		const ___map_poi___collection: type___api___response = await this._API.map___read___poi([ this.mapbox___map___center___lng, this.mapbox___map___center___lat ], this.mapbox___map___collection_poi___ids, this._ABORT___SIGNAL);
		if (___map_poi___collection['response'] !== 'error') {
			this.funcs___mapbox.map___load___icon___customs(this.mapbox___map, this.mapbox___map___collection_events['features']);
			this.mapbox___map___collection_poi['features'] = [...this.mapbox___map___collection_poi['features'], ...___map_poi___collection['data']['features']];
			this.mapbox___map___collection_poi___ids = this.mapbox___map___collection_poi['features'].map((___f: any) => { return ___f['properties']['marker_id']; })
			if (this.mapbox___map.getSource(this.mapbox___map___collection_poi___source_name)) {
				(this.mapbox___map.getSource(this.mapbox___map___collection_poi___source_name) as mapboxgl.GeoJSONSource)
					.setData(this.mapbox___map___collection_poi);
			} else {
				this.map___addSource___poi();
			}
			this.mapbox___map.setLayoutProperty(this.mapbox___map___collection_poi___source_layer, 'visibility', 'visible');
			this.handle___map___onClickElement();
		}
		this.setState({
			map___isLoading___poi: false,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___map___onClickElement = () => {
		if (this.mapbox___map___onClickElement === false)
		{
			this.mapbox___map___onClickElement = true;
			this.mapbox___map.on('click', (___e: any) => {

				const ___map___features: GeoJSONFeature[] = this.mapbox___map.queryRenderedFeatures(___e.point, {
					layers: this.mapbox___map___layer_collection,
				});
			
				if (___map___features.length > 0) {

					const ___map___marker: GeoJSONFeature = ___map___features[0];
	
					const ___map___marker___type: type___map___element___type = ___map___marker['properties']['marker_type'] as type___map___element___type;
					const ___map___marker___id: string = ___map___marker['properties']['marker_id'] as string;
					const ___map___marker___props: any = ___map___marker['properties']['marker_props'] ?? null;
					const ___map___marker___props___parsed: any = (___map___marker___props) ? JSON.parse(___map___marker___props) : null;		//	workaround

					this.setState({
						map___element_pane___content_type: ___map___marker___type,
						map___element_pane___content_id: ___map___marker___id,
						map___element_pane___content_props: ___map___marker___props___parsed,
					}, () => {
						this.handle___select___map_element(___map___marker___type, ___map___marker___id, ___map___marker___props___parsed);
					});
	
				}
			});
		}
	}

	private handle___select___map_element = (___type: type___map___element___type, ___id: string, ___props: any | null = null) => {
		this.setState({
			map___element_pane: 'element',
			map___element_pane___content_type: ___type,
			map___element_pane___content_id: ___id,
			map___element_pane___content_props: ___props,
		});
	}

	private handle___discover___search___input = (___search_param: string) => {
		this.setState({
			discover___search___param: ___search_param,
			discover___search_cities: [],
			discover___search_cities___isLoading: true,
			discover___search_poi: [],
			discover___search_poi___isLoading: true,
		}, () => {
			this.handle___discover___search(this.state.discover___search___param);
		});
	}

	private handle___discover___search = (___search_param: string) => {
		if (this.state.discover___search___param.length > 0) {
			(async () => {
				await Promise.all([
					this.read_props___search___locations(),
					this.read_props___search___poi(),
				]);
			})();
		}
	}

	private handle___discover___search_preview___route_to_coords = (___new_coords: type___geo___lngLat) => {
		const ___new_coords___lng: number = ___new_coords['lng'];
		const ___new_coords___lat: number = ___new_coords['lat'];
		this.map___fly___to(___new_coords___lng, ___new_coords___lat);
		
	}

	private handle___discover___search_preview___dismiss = (___full_search: boolean = false) => {
		this.setState({
			discover___search___param: (___full_search === true) ? this.state.discover___search___param : '',
			discover___search_cities: [],
			discover___search_cities___isLoading: true,
			discover___search_poi: [],
			discover___search_poi___isLoading: true,
			ionModal___MdDiscoverSearchModal___isOpen: ___full_search,
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {

		this._ABORT___CONTROLLER = new AbortController();
		this._ABORT___SIGNAL = this._ABORT___CONTROLLER.signal;

		this.setState({ 
			...state___SbDiscoverMapPage___defaults
		}, () => {

		//	this.mapbox___map___isLoading = true;
		//	this.mapbox___map___isLoading___icons = true;

			this.map___get_center()
				.then(() => {
					setTimeout(() => {
						this.map___prepare();
					}, 250);
				});

		});
	}

	ionViewWillLeave() : void {
		if (this._ABORT___CONTROLLER) { this._ABORT___CONTROLLER.abort(); }
	}

	ionViewDidLeave() : void {
/*
		this.setState({ 
			...state___SbDiscoverMapPage___defaults
		}, () => {

		//	this.mapbox___map___isLoading = true;
		//	this.mapbox___map___isLoading___icons = true;

			this.mapbox___map___collection_events = this.mapbox___map___layer_constructor;
			this.mapbox___map___collection_events___ids = [];

			this.mapbox___map___collection_poi = this.mapbox___map___layer_constructor;
			this.mapbox___map___collection_poi___ids = [];

			this.mapbox___map___collection_tales = [];
			this.mapbox___map___collection_tales___ids = [];

		//	if (this.mapbox___map) { this.mapbox___map.remove(); }

		});
*/
/*

		this.setState({ 
			...state___SbDiscoverMapPage___defaults
		}, () => {

			this.mapbox___map___collection_poi = { type: "FeatureCollection", features: [] };
			this.mapbox___map___collection_poi___ids = [];
			this.mapbox___map.remove();

		});

/*/

	}

//#endregion

//#region 																							R E N D E R

	render() {
		return <>
			<IonPage>

				<MdDiscoverCompassComponent isOpen={ this.state.ionModal___MdDiscoverCompassModal___isOpen } event___onDidDismiss={() => { this.setState({ ionModal___MdDiscoverCompassModal___isOpen: false }); }}/>
				<MdDiscoverSearchModal isOpen={ this.state.ionModal___MdDiscoverSearchModal___isOpen} search_param={ this.state.discover___search___param } event___onDidDismiss={() => { this.setState({ ionModal___MdDiscoverSearchModal___isOpen: false, discover___search___param: '' }); }}/>

				<IonContent forceOverscroll={ false } scrollX={ false } scrollY={ false }>
					<div className="sb-page---container ignore-limits">

						<div className="sb-discover-map---header">
							<ComHeaderLogoComponent logo___theme="light"/>
							<div>
								<label htmlFor="input---map-search" style={{width:'100%'}}>
									<i className="fas fa-search"></i>
									<input type="text" id="input---map-search" placeholder={ this._LOCALE.translate(locale___SbDiscoverMapPage.search_something) } value={ this.state.discover___search___param } onInput={(___e: any) => { this.handle___discover___search___input(___e.target.value); }}/>
								</label>
								<div onClick={() => { this.setState({ ionModal___MdDiscoverSearchModal___isOpen: true }); }}><i className="fas fa-search"></i></div>
							</div>
							{(this.state.map___isLoading___events === true || this.state.map___isLoading___poi === true) ? <>
								<section className="sb-discover-map---header---loader">
									<ComSpinnerTagComponent/>
								</section>
							</> : <></>}
						</div>

						{/**
						 * 
						 * 
						 * 
						 * 	cerca anche frasi tipo "mi son sentito male e simili"
						 * 
						 * 
						 * 
						 */}

						{(this.state.discover___search___param.length > 0) ? <>
							<MdDiscoverSearchPreviewModal isOpen={ this.state.discover___search___param.length > 0 } search_results___collection={[ ...this.state.discover___search_cities, ...this.state.discover___search_poi ]} search_results___collection___isLoading={ this.state.discover___search_cities___isLoading || this.state.discover___search_poi___isLoading } event___onMapRedirecting={ this.handle___discover___search_preview___route_to_coords } event___onDidDismiss={ this.handle___discover___search_preview___dismiss }/>
						</> : <></>}

						{/*
						 *
						 *
						 *
							{(this.mapbox___map___loaded === false) ? <>
								<div className="sb-discover-map---container-loader">
									<ComSpinnerComponent size="standard"/>
								</div>
							</> : <></>}
						 *
						 *
						 *
						 */}

						<div className="sb-discover-map---container">
							<div ref={ this.mapbox___map___container } id="sb-discover-map---map" className="sb-discover-map---map" />
						</div>

						{(() => { switch (this.state.map___element_pane) {
							case 'element': return <>
								<ComDiscoverElementComponent element___type={ this.state.map___element_pane___content_type } element___id={ this.state.map___element_pane___content_id } element___props={ this.state.map___element_pane___content_props }/>
							</>; break;
						}})()}

						<div className="sb-discover-map---navigation---main">
							<div className="is-element" style={{display:'none'}}></div>
							<div  className="is-element sb-discover-map---navigation---main---center" onClick={() => { this.setState({ ionModal___MdDiscoverCompassModal___isOpen: true }); }}>
								<i className="far fa-compass"></i>
							</div>
							<Link to="/info/presentation" className="is-element">
								<i className="fas fa-arrow-right"></i>
							</Link>
						</div>

					</div>
				</IonContent>
			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbDiscoverMapPage);