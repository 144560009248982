import { RouteComponentProps } from "react-router";

//	P R O P S

export interface props___SbEventsViewPage extends RouteComponentProps<{ id: string; }> {

}

//	S T A T E

export interface state___SbEventsViewPage {

	event___id: string,

	event___props: any | null,
	event___props___isLoading: boolean,

	event___invite: any | null,
	event___poi___props: any | null,
	event___user___props: any | null,

	ionModal___MdMediaViewModal___visible: boolean,
	ionModal___MdMediaViewModal___argument: string,

}

//	S T A T E   D E F A U L T

export const state___SbEventsViewPage___default: state___SbEventsViewPage = {

	event___id: '',

	event___props: null,
	event___props___isLoading: true,

	event___invite: null,
	event___poi___props: null,
	event___user___props: null,

	ionModal___MdMediaViewModal___visible: false,
	ionModal___MdMediaViewModal___argument: '',

}