import { type___geo___lngLat } from "../../types/types.types"

//	P R O P S

export interface props___ComMapPreviewComponent {

	map_coords: type___geo___lngLat,
	canOpenRoute?: boolean,

}

//	S T A T E

export interface state___ComMapPreviewComponent {

	coords: type___geo___lngLat,

	ionActionSheet___mapNavigation___isOpen: boolean

}

//	S T A T E   D E F A U L T

export const state___ComMapPreviewComponent___default: state___ComMapPreviewComponent = {

	coords: { lng: 0, lat: 0 },

	ionActionSheet___mapNavigation___isOpen: false

}