import React from 'react'
import { IonItem, IonLabel } from '@ionic/react';

//	S T Y L E

import './com-event-category.scss'

//	S T A T E S   -   P R O P S

import { props___ComEventCategoryComponent } from './com-event-category.state';

//	C L A S S

export default class ComEventCategoryComponent extends React.Component<props___ComEventCategoryComponent, {}>
{

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComEventCategoryComponent,
	) {
		super(props);
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonItem className="com-event-category-component---container is-ion-item-button" button={(this.props.isButton && this.props.isButton === true)}>
				<img src={ '/assets/markers/events/' + this.props.event_category___props['event_category_icon'] + '.svg' }/>
				<IonLabel>{ this.props.event_category___props['event_category_name'] }</IonLabel>
			</IonItem>
		</>;
	}

//#endregion

}