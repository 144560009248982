export const locale___MdDigitalMenuDishModal = {
	'topBarButton___close': {
		de_de: 'Schließen',
		en_us: 'Close',
		es_es: 'Cerca',
		fr_fr: 'Fermer',
		it_it: 'Chiudi',
	},
	'allergens___can_contain': {
		de_de: 'enthalten kann',
		en_us: 'can contain',
		es_es: 'puede contener',
		fr_fr: 'peut contenir',
		it_it: 'può contenere',
	},
	'allergens___not_containing': {
		de_de: 'enthält keine allergene',
		en_us: 'does not contain allergens',
		es_es: 'no contiene alérgenos',
		fr_fr: 'ne contient pas d\'allergènes',
		it_it: 'non contiene allergeni',
	},
	'button___allergen_list': {
		de_de: 'Informationen zu allergenen',
		en_us: 'allergen information',
		es_es: 'información de alérgenos',
		fr_fr: 'informations sur les allergènes',
		it_it: 'informazioni sugli allergeni',
	},
};