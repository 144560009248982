//	P R O P S

export interface props___SbLandingGeolocationPage {

	event___onGeolocationAvailable: () => void

}

//	S T A T E

export interface state___SbLandingGeolocationPage {

	ionModal___MdEntryCookiesModal___isOpen: boolean,

}

//	S T A T E   D E F A U L T S

export const state___SbLandingGeolocationPage___default: state___SbLandingGeolocationPage = {

	ionModal___MdEntryCookiesModal___isOpen: false,

}