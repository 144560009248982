import React from 'react'
import { IonCard, IonItem, IonLabel, IonList, IonNote } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___ComPoiTimetableComponent } from './com-poi-timetable.locale';

//	S T Y L E

import './com-poi-timetable.scss'

//	S T A T E S   -   P R O P S

import { props___ComPoiTimetableComponent, state___ComPoiTimetableComponent, state___ComPoiTimetableComponent___default } from './com-poi-timetable.state';

//	F U N C S

import { funcs_datetime } from '../../funcs/funcs.datetime';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C O M P O N E N T S

import ComPoiTimetableTableComponent from '../com-poi-timetable-table/com-poi-timetable-table';

//	C L A S S

export default class ComPoiTimetableComponent extends React.Component<props___ComPoiTimetableComponent, state___ComPoiTimetableComponent>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	
	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComPoiTimetableComponent,
	) {
		super(props);
		this.state = state___ComPoiTimetableComponent___default;
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			{(this.props.timetable_props && this.props.timetable_props !== 'none' && this.props.timetable_props !== null) ? <>
				<IonCard className="com-poi-timetable---container">
					{(this.props.timetable_props === 'h24') ? <>
						<IonList>
							<IonItem className="is-ion-item-button" style={{textAlign:'center'}}>
								<IonLabel><b>{ this._LOCALE.translate(locale___ComPoiTimetableComponent.open__h24) }</b></IonLabel>
							</IonItem>
						</IonList>
					</> : <>
						<IonList>
							<label htmlFor="com-poi-timetable---cb-table-view">
								<IonItem className="is-ion-item-button" style={{textAlign:'center'}}>
									<i className="fas fa-clock"></i>
									<IonLabel>
										{(this.funcs___datetime.datetime___get___isOpen(this.props.timetable_props) === true) ? <>
											<b>{ this._LOCALE.translate(locale___ComPoiTimetableComponent.open__now) }</b>
										</> : <>
											<b>{ this._LOCALE.translate(locale___ComPoiTimetableComponent.closed___now) }</b>
										</>}
									</IonLabel>
									<IonNote slot="end">
										{(this.state.full_table___visible === false) ? <>
											<i className="fas fa-plus"></i>
										</> : <>
											<i className="fas fa-minus"></i>
										</>}
									</IonNote>
								</IonItem>
							</label>
							<input id="com-poi-timetable---cb-table-view" type="checkbox" checked={ this.state.full_table___visible } onChange={(___e: any) => { this.setState({ full_table___visible: ___e.target.checked }); }}/>
							<section className="com-poi-timetable---table">
								<ComPoiTimetableTableComponent timetable_week_props={ this.props.timetable_props }/>
							</section>
						</IonList>
					</>}
				</IonCard>
			</> : <></>}
		</>;
	}

//#endregion

}