import { type___poi___timetable } from "../../types/types.types"

//	P R O P S

export interface props___ComPoiTimetableComponent {

	timetable_props: type___poi___timetable,

}

//	S T A T E

export interface state___ComPoiTimetableComponent {

	full_table___visible: boolean,

}

//	S T A T E   D E F A U L T

export const state___ComPoiTimetableComponent___default: state___ComPoiTimetableComponent = {

	full_table___visible: false,

}