import React from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonLabel, IonListHeader, IonModal, IonToolbar } from '@ionic/react';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';

//	L O C A L I Z A T I O N

import { locale___MdDiscoverSearchModal } from './md-discover-search.locale';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___modal___config } from '../../types/types.modals';

//	S T Y L E

import './md-discover-search.scss';

//	S T A T E   -   P R O P S

import { props___MdDiscoverSearchModal, state___MdDiscoverSearchModal, state___MdDiscoverSearchModal___default } from './md-discover-search.state';

//	L I B S

import { lib_names } from '../../libs/lib.names';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';
import { service_LocalizationService } from '../../services/service-localization';

//	C O M P O N E N T S

import ComEventComponent from '../../components/com-event/com-event';
import ComInfiniteScrollerComponent from '../../components/com-infinite-scroller/com-infinite-scroller';
import ComListEmptyComponent from '../../components/com-list-empty/com-list-empty';
import ComListSeparatorComponent from '../../components/com-list-separator/com-list-separator';
import ComPoiComponent from '../../components/com-poi/com-poi';
import ComUserComponent from '../../components/com-user/com-user';

//	C L A S S

export default class MdDiscoverSearchModal extends React.Component<props___MdDiscoverSearchModal, state___MdDiscoverSearchModal>
{

//#region 																							C O N F I G

	private readonly MdDiscoverSearchModal___modal_config: type___modal___config = {
		breakpoints: [ 1 ],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); },
	};

//#endregion

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdDiscoverSearchModal,
	) {
	
		super(props);
		this.state = state___MdDiscoverSearchModal___default;

		this.constructor___debounce();

	}

	constructor___debounce() : void {
		this.handle___discover___search___input = this.handle___discover___search___input.bind(this);
		this.handle___discover___search = debounce(this.handle___discover___search.bind(this), lib_names.lodash.debounce_delay);
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___search_results = async () => {
		const ___temp___search_results___props: type___api___response = await this._API.search___full(this.state.search___target, this.state.search___param, this.state.search___results.length);
		this.setState((prevState) => ({
			search___results: [...prevState.search___results, ...___temp___search_results___props.data],
			search___results___isLoading: false,
			search___results___is_complete: (___temp___search_results___props.response === 'no-data') ? true : false,
		}));
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___discover___search___input = (___search_param: string) => {
		this.setState({
			search___param: ___search_param,
			search___results___isLoading: true,
			search___results: [],
		}, () => {
			this.handle___discover___search(this.state.search___param);
		});
	}

	private handle___discover___search = (___search_param: string) => {
		this.setState({
			search___param: ___search_param,
			search___results: [],
			search___results___isLoading: true,
		}, async () => {
			if (this.state.search___param && this.state.search___param.length > 0) {
				await this.read_props___search_results();
			}
		});
	}

	private handle___discover___search___switch_target = (___search_target: state___MdDiscoverSearchModal['search___target']) => {
		this.setState({
			search___results: [],
			search___target: ___search_target,
			search___results___isLoading: true,
		}, async () => {
			this.handle___discover___search(this.state.search___param);
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	private event___onDidPresent() : void {
		this.setState({
			...state___MdDiscoverSearchModal___default
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonModal isOpen={ this.props.isOpen } {...this.MdDiscoverSearchModal___modal_config} handleBehavior="cycle">

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={ this.props.event___onDidDismiss }>
								<b>{ this._LOCALE.translate(locale___MdDiscoverSearchModal.topBarButton___close) }</b>
							</IonButton>
						</IonButtons>
					</IonToolbar>
					<IonListHeader>
						<IonLabel>
							<section style={{display:'flex',justifyContent:'space-between'}}>
								<span>
									<i className="fas fa-search" style={{paddingRight:'15pt'}}></i>
									<span>{ this._LOCALE.translate(locale___MdDiscoverSearchModal.title) }</span>
								</span>
							</section>
						</IonLabel>
					</IonListHeader>
					<section className="md-discover-search---header">
						<label className="md-discover-search---search-bar" htmlFor="input---discover-search-full">
							<i className="fas fa-search"></i>
							<input type="text" id="input---discover-search-full" placeholder={ this._LOCALE.translate(locale___MdDiscoverSearchModal.search_something) } value={ this.state.search___param } onInput={(___e: any) => { this.handle___discover___search___input(___e.target.value); }}/>
						</label>
						<br />
						<section className="md-discover-search---search-selector">
							<span>{ this._LOCALE.translate(locale___MdDiscoverSearchModal.search_target) }</span>
							<input type="radio" name="md-discover-search---search-selector" id="md-discover-search---search-selector---poi" checked={ this.state.search___target === 'poi' } onChange={() => { this.handle___discover___search___switch_target('poi'); }}/>
							<label htmlFor="md-discover-search---search-selector---poi">{ this._LOCALE.translate(locale___MdDiscoverSearchModal.search_target___poi) }</label>
							<input type="radio" name="md-discover-search---search-selector" id="md-discover-search---search-selector---events" checked={ this.state.search___target === 'events' } onChange={() => { this.handle___discover___search___switch_target('events'); }}/>
							<label htmlFor="md-discover-search---search-selector---events">{ this._LOCALE.translate(locale___MdDiscoverSearchModal.search_target___events) }</label>
						</section>
					</section>
				</IonHeader>

				<IonContent forceOverscroll={ false }>
					<section className="md-discover-search---container">
						{(this.state.search___param && this.state.search___param.length > 0) ? <>
						
							{(this.state.search___results.length > 0) ? <>
								<section>
									{this.state.search___results.map((___result: any, ___result___idx: number) => { return <>
										{(() => { switch (this.state.search___target) {
											case 'events': return <>
												<Link key={ ___result___idx } to={ '/view/events/' + ___result['event_id'] } onClick={ this.props.event___onDidDismiss }>
													<ComEventComponent event_props={ ___result } size="normal"/>
												</Link>
											</>; break;
											case 'poi': return <>
												<Link key={ ___result___idx } to={ '/view/poi/' + ___result['poi_id'] } onClick={ this.props.event___onDidDismiss }>
													<ComPoiComponent poi_props={ ___result } size="normal"/>
												</Link>
											</>; break;
										}})()}
										<ComListSeparatorComponent />
									</>;})}
								</section>
							</> : <></>}

							{(this.state.search___results___is_complete === true) ? <>
								<ComListEmptyComponent list_items_quantity={ this.state.search___results.length }/>
							</> : <>
								<ComInfiniteScrollerComponent is_loading={ this.state.search___results___isLoading } event___scrollTriggered={ this.read_props___search_results } />
							</>}
							
						</> : <>
							<br />
							<br />
							<br />
							<ComListEmptyComponent text={ this._LOCALE.translate(locale___MdDiscoverSearchModal.search_someting_to_display) }/>
						</>}
					</section>
				</IonContent>

			</IonModal>
		</>;
	}

//#endregion

}