import React from 'react'

//	S T Y L E

import './com-spinner-refresher.scss'

//	C L A S S

export default class ComSpinnerRefresherComponent extends React.Component<{}, {}>
{

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="com-spinner-refresher---component">
				<i className="fa-solid fa-circle-notch"></i>
			</div>
		</>;
	}

//#endregion

}