import React from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonModal, IonToolbar } from '@ionic/react';
import { Link } from 'react-router-dom';

//	L O C A L I Z A T I ON

import { locale___MdDigitalMenuDishModal } from './md-digitalmenu-dish-view.locale';

//	S T Y L E

import './md-digitalmenu-dish-view.scss';

//	S T A T E   -   P R O P S

import { props___MdDigitalMenuDishModal, state___MdDigitalMenuDishModal, state___MdDigitalMenuDishModal___default } from './md-digitalmenu-dish-view.state';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___modal___config } from '../../types/types.modals';

//	F U N C S

import { funcs_measurements } from '../../funcs/funcs.measurements';
import { funcs_numbers } from '../../funcs/funcs.numbers';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';
import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComGalleryPlaceholderComponent from '../../components/com-gallery-placeholder/com-gallery-placeholder';
import ComSpinnerInlineComponent from '../../components/com-spinner-inline/com-spinner-inline';

//	C L A S S

export default class MdDigitalMenuDishModal extends React.Component<props___MdDigitalMenuDishModal, state___MdDigitalMenuDishModal>
{

//#region 																							C O N F I G

	private readonly MdDigitalMenuDishModal___modal_config: type___modal___config = {
		breakpoints: [ 1 ],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); },
		onDidDismiss: () => { this.event___onDidDismiss(); }
	};

//#endregion

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

	private readonly funcs___measurements: funcs_measurements = new funcs_measurements();
	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdDigitalMenuDishModal,
	) {
		super(props);
		this.state = state___MdDigitalMenuDishModal___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___dish_props = async () => {
		const ___temp___dish_props: type___api___response = await this._API.digitalmenus_dishes___read___single(this.props.dish_id)
		this.setState({
			dish___props: ___temp___dish_props.data,
			dish___props___isLoading: false,
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	private event___onDidPresent() : void {
		this.setState({
			...state___MdDigitalMenuDishModal___default
		}, async () => {
			await this.read_props___dish_props();
		});
	}

	private event___onDidDismiss() : void {
		this.setState({
			...state___MdDigitalMenuDishModal___default
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonModal isOpen={ this.props.isOpen } {...this.MdDigitalMenuDishModal___modal_config}>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={ this.props.event___onDidDismiss }>
								<b>{ this._LOCALE.translate(locale___MdDigitalMenuDishModal.topBarButton___close) }</b>
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>
					{(this.state.dish___props___isLoading === true) ? <>

						<br />
						<br />
						<br />
						<br />
						<ComSpinnerInlineComponent/>
					
					</> : <>
					
						<section className="md-modal---container md-digitalmemu-dish-view---container">
							
							<ComGalleryPlaceholderComponent placeholder_path={ '/assets/digitalmenu/categories/' + this.state.dish___props['dish_category']['dish_category_icon'] + '.svg' }/>

							<br />

							<h6>{ this.state.dish___props['dish_category']['dish_category_name'] }</h6>
							<h4>{ this.state.dish___props['dish_name'] }</h4>

							<br />

							{(this.state.dish___props['dish_description'] && this.state.dish___props['dish_description'].length > 0) ? <>
								<p>{ this.state.dish___props['dish_description'] }</p>
								<br />
							</> : <></>}

							<h5>
								{(this.state.dish___props['dish_measurement_unit'] && this.state.dish___props['dish_measurement_unit'] !== null) ? <>
									<span>{ this.funcs___numbers.format___price(this.state.dish___props['dish_price'], '€') } </span>
									<span> { this.funcs___measurements.price_at_measurements(this.state.dish___props['dish_measurement_unit'], true) }</span>
								</> : <>
									<span>{ this.funcs___numbers.format___price(this.state.dish___props['dish_price'], '€') }</span>
								</>}
							</h5>

							<br />
							<br />

							{(this.state.dish___props['dish_allergens'] && this.state.dish___props['dish_allergens'].length > 0) ? <>
								<h6>{ this._LOCALE.translate(locale___MdDigitalMenuDishModal.allergens___can_contain) }</h6>
								<section className="md-digitalmemu-dish-view---allergens">
									{this.state.dish___props['dish_allergens'].map((___a: any, ___a___idx: number) => {
										return <>
											<div>
												<img src={ '/assets/digitalmenu/allergens/' + ___a['dish_allergen_icon'] + '.svg' } />
											</div>
										</>;
									})}
								</section>
							</> : <>
								<h6>{ this._LOCALE.translate(locale___MdDigitalMenuDishModal.allergens___not_containing) }</h6>
							</>}

							<br />

							<Link to="/info/digitalmenu/allergens" onClick={ this.props.event___onDidDismiss }>
								<span>{ this._LOCALE.translate(locale___MdDigitalMenuDishModal.button___allergen_list) }</span>
								<i className="fas fa-chevron-right"></i>
							</Link>

						</section>

					</>}
				</IonContent>

			</IonModal>
		</>;
	}

//#endregion

}