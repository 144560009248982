import { type___post___attachment_type } from "../../types/types.types";

//	P R O P S

export interface props___ComButtonShareComponent {

	element_type: type___post___attachment_type,
	element: any,

}

//	S T A T E

export interface state___ComButtonShareComponent {

	element___title: string,
	element___url: string,

}

//	S T A T E   D E F A U L T

export const state___ComButtonShareComponent___default: state___ComButtonShareComponent = {

	element___title: '',
	element___url: '',

}