import mapboxgl from 'mapbox-gl';

//	E X P O R T   F U N C S

export class funcs_mapbox
{

//#region 																							L O A D   E L E M E N T   I C O N

	public map___load___icon = async (___map_istance: mapboxgl.Map, ___icon: string, ___type: 'events' | 'poi') => {
		return new Promise<void>((resolve, reject) => {
			const ___marker___icon_path: string = '/assets/markers/' + ___type + '/' + ___icon + '.png';
			const ___marker___icon_name: string = 'map_' + ___type + '_' + ___icon;
			___map_istance.loadImage(___marker___icon_path, (___err, ___i) => {
				if (___err) { reject(___err); } else { ___map_istance.addImage(___marker___icon_name, ___i); resolve(); }
			});
		});
	};

	public map___load___icon___customs = async (___map_istance: mapboxgl.Map, ___features: GeoJSON.FeatureCollection<GeoJSON.Geometry>['features']) => {
		return new Promise<void>((resolve, reject) => {
			___features.forEach((___f: any) => {
				if (___f['properties']['marker_icon_custom'] && ___f['properties']['marker_icon_custom'] !== 'null') {
					const ___marker___icon_custom_path: string = ___f['properties']['marker_icon_custom_path'];
					const ___marker___icon_custom_name: string = ___f['properties']['marker_icon_custom'];
					if (!___map_istance.hasImage(___marker___icon_custom_name)) {
						___map_istance.loadImage(___marker___icon_custom_path, (___err, ___i) => {
							if (___err) { reject(___err); } else { ___map_istance.addImage(___marker___icon_custom_name, ___i); resolve(); }
						});
					}
				}
			});
		});
	};

//#endregion

}