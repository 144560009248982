export const locale___SbEventsCategoryViewPage = {
	'category_event': {
		de_de: 'Veranstaltungen',
		en_us: 'Events',
		es_es: 'Eventos',
		fr_fr: 'Événements',
		it_it: 'Eventi',
	},
	'category_event___all': {
		de_de: 'Alle Veranstaltungen',
		en_us: 'All Events',
		es_es: 'Todos Los Eventos',
		fr_fr: 'Tous Les Événements',
		it_it: 'Tutti gli Eventi',
	},
};