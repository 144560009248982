import { interface___modal } from "../../interfaces/interface.modals";

//	P R O P S

export interface props___MdDigitalMenuDishModal extends interface___modal {

	dish_id: string,
	
}

//	S T A T E

export interface state___MdDigitalMenuDishModal {

	dish___props: any | null,
	dish___props___isLoading: boolean,




	

}

//	S T A T E   D E F A U L T

export const state___MdDigitalMenuDishModal___default: state___MdDigitalMenuDishModal = {

	dish___props: null,
	dish___props___isLoading: true,
	











}