export const locale___MdDiscoverSearchModal = {
	'topBarButton___close': {
		de_de: 'Schließen',
		en_us: 'Close',
		es_es: 'Cerca',
		fr_fr: 'Fermer',
		it_it: 'Chiudi',
	},
	'title': {
		de_de: 'Nahe',
		en_us: 'Search',
		es_es: 'Cerca',
		fr_fr: 'Rechercher',
		it_it: 'Cerca',
	},
	'search_something': {
		de_de: 'Nach etwas suchen...',
		en_us: 'Search something...',
		es_es: 'Busca algo...',
		fr_fr: 'Rechercher quelque chose...',
		it_it: 'Cerca qualcosa...',
	},
	'search_target': {
		de_de: 'ziel:',
		en_us: 'target:',
		es_es: 'objetivo:',
		fr_fr: 'cible:',
		it_it: 'target:',
	},
	'search_target___poi': {
		de_de: 'Sehenswürdigkeiten',
		en_us: 'Point of interests',
		es_es: 'Puntos de interés',
		fr_fr: 'Points d\'interêts',
		it_it: 'Punti di interesse',
	},
	'search_target___events': {
		de_de: 'Veranstaltungen',
		en_us: 'Events',
		es_es: 'Eventos',
		fr_fr: 'Événements',
		it_it: 'Eventi',
	},
	'search_target___users': {
		de_de: 'Menschen',
		en_us: 'People',
		es_es: 'Gente',
		fr_fr: 'Gens',
		it_it: 'Persone',
	},
	'search_someting_to_display': {
		de_de: 'Geben Sie einen Suchbegriff ein, um Ergebnisse anzuzeigen...',
		en_us: 'Enter a search term to view results...',
		es_es: 'Ingrese un término de búsqueda para ver los resultados...',
		fr_fr: 'Entrez un terme de recherche pour afficher les résultats...',
		it_it: 'Inserisci un termine di ricerca per visualizzare i risultati...',
	}
};