import { type___geo___lngLat } from "../../types/types.types"

//	P R O P S

export interface props___ComMapPreviewAddressComponent {

	coords: type___geo___lngLat,

	canOpenRoute?: boolean,

}

//	S T A T E

export interface state___ComMapPreviewAddressComponent {

	coords: type___geo___lngLat,
	address: string,

	ionActionSheet___mapNavigation___isOpen: boolean,

}

//	S T A T E   D E F A U L T

export const state___ComMapPreviewAddressComponent___default: state___ComMapPreviewAddressComponent = {

	coords: { lat: 0, lng: 0 },
	address: '...',

	ionActionSheet___mapNavigation___isOpen: false,

}