import React from 'react'
import { IonCard, IonItem, IonLabel, IonList } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___ComPoiComponent } from './com-poi.locale';

//	S T Y L E

import './com-poi.scss'

//	S T A T E S   -   P R O P S

import { props___ComPoiComponent } from './com-poi.state';

//	F U N C S

import { funcs_datetime } from '../../funcs/funcs.datetime';
import { funcs_numbers } from '../../funcs/funcs.numbers';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C O M P O N E N T S

import ComGalleryPlaceholderComponent from '../com-gallery-placeholder/com-gallery-placeholder';
import ComPoiVerifiedComponent from '../com-poi-verified/com-poi-verified';

//	C L A S S

export default class ComPoiComponent extends React.Component<props___ComPoiComponent, {}>
{

//#region 																							C O N S T R U C T O R
		
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();
	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			{(this.props) ? <>
				{(() => { switch (this.props.size) {
					case 'large': return <>
						<section className="com-poi-component---large">
							{(this.props.poi_props['poi_gallery'] && this.props.poi_props['poi_gallery'].length > 0) ? <>
								<img src={ this.props.poi_props['poi_gallery'][0] }/>
							</> : <>
								{(this.props.poi_props['poi_category'] && this.props.poi_props['poi_category']['poi_category_icon']) ? <>
									<ComGalleryPlaceholderComponent placeholder_path={ '/assets/markers/poi/' + this.props.poi_props['poi_category']['poi_category_icon'] + '.svg' } />
								</> : <>
									<ComGalleryPlaceholderComponent placeholder_path={ '/assets/markers/poi.svg' } />
								</>}
							</>}
							<h5>
								<span>{ this.props.poi_props['poi_name'] }</span>
								{(this.props.poi_props['poi_verified'] && this.props.poi_props['poi_verified'] === true) ? <>
									<ComPoiVerifiedComponent popup={ true }/>
								</> : <></>}
							</h5>
						</section>
					</>; break;
					case 'normal': return <>
						<IonCard className="com-poi-component---medium">
							{(this.props.poi_props['poi_gallery'] && this.props.poi_props['poi_gallery'].length > 0) ? <>
								<img src={ this.props.poi_props['poi_gallery'][0] }/>
							</> : <>
								{(this.props.poi_props['poi_category'] && this.props.poi_props['poi_category']['poi_category_icon']) ? <>
									<div>
										<img src={ '/assets/markers/poi/' + this.props.poi_props['poi_category']['poi_category_icon'] + '.svg'}/>
										<img src={ '/assets/markers/poi/' + this.props.poi_props['poi_category']['poi_category_icon'] + '.svg'}/>
									</div>
								</> : <>
									<div>
										<img src={ '/assets/markers/poi.svg'}/>
										<img src={ '/assets/markers/poi.svg'}/>
									</div>
								</>}
							</>}
							<section>
							{/*	<span *ngIf="this.props.poi___props['poi_verified'] === true" className="chip---verified">
									<i className="fas fa-check"></i>
									<span>Proprietario verificato</span>
								</span>
				*/}
								<h5>
									<span>{ this.props.poi_props['poi_name'] }</span>
									{(this.props.poi_props['poi_verified'] && this.props.poi_props['poi_verified'] === true) ? <>
										<ComPoiVerifiedComponent popup={ false }/>
									</> : <></>}
								</h5>
								<p>
									<i className="fas fa-map-marked-alt"></i>
									{(this.props.poi_props['poi_coords_distance']) ? <>
										<span>{ this._LOCALE.translate(locale___ComPoiComponent.far_from_you, [this.funcs___numbers.number___format_as_string___distance(this.props.poi_props['poi_coords_distance'])]) }</span>
									</> : <>
										<span>{ this._LOCALE.translate(locale___ComPoiComponent.far_from_you___loading) }</span>
									</>}
								</p>
								{(this.props.poi_props['poi_timetable'] && this.props.poi_props['poi_timetable'] !== null && this.props.poi_props['poi_timetable'] !== 'none') ? <>
									{(this.funcs___datetime.datetime___get___isOpen(this.props.poi_props['poi_timetable']) === true) ? <>
										<p>
											<i className="fas fa-clock"></i>
											<span>{ this._LOCALE.translate(locale___ComPoiComponent.is_open) }</span>
										</p>
									</> : <>
										<p style={{opacity:0.25}}>
											<i className="fas fa-clock"></i>
											<span>{ this._LOCALE.translate(locale___ComPoiComponent.is_closed) }</span>
										</p>
									</>}
								</> : <></>}
							</section>
						</IonCard>
					</>; break;
					case 'small': return <>
						<IonCard className="com-poi-component---small">
							<IonList>
								<IonItem className="is-ion-item-button">
									{(this.props.poi_props['poi_gallery'] && this.props.poi_props['poi_gallery'].length > 0) ? <>
										<img src={ this.props.poi_props['poi_gallery'][0] }/>
									</> : <>
										{(this.props.poi_props['poi_category'] && this.props.poi_props['poi_category']['poi_category_icon']) ? <>
											<div>
												<img src={ '/assets/markers/poi/' + this.props.poi_props['poi_category']['poi_category_icon'] + '.svg'}/>
											</div>
										</> : <>
											<div>
												<img src={ '/assets/markers/poi.svg'}/>
											</div>
										</>}
									</>}
									<IonLabel>{ this.props.poi_props['poi_name'] }</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>
					</>; break;
				}})()}
			</> : <></>}
		</>;
	}

//#endregion

}