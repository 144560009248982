import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	L O C A L I Z A T I O N 

import { locale___App } from '../../../App.locale';
import { locale___SbInfoCopyrightPage } from './sb-info-copyright.locale';

//	S T Y L E

import './sb-info-copyright.scss';

//	C O M P O N E N T S

import ComCopyrightComponent from '../../../components/com-copyright/com-copyright';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';

//	C L A S S

class SbInfoCopyrightPage extends React.Component<{}, {}>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: {},
	) {
		super(props);
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/settings" text={ this._LOCALE.translate(locale___App.ionBackButton___text) } />
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>
					<section className="sb-info-copyright---container">
						<section className="sb-info-copyright---container---motto-logo">
							<img src="/assets/logo.horizontal.svg"/>
							<h4>{ this._LOCALE.translate(locale___SbInfoCopyrightPage.motto_title) }</h4>
							<p>{ this._LOCALE.translate(locale___SbInfoCopyrightPage.motto_description) }</p>
						</section>
						<br/>
						<br/>
						<br/>
						<ComCopyrightComponent/>
						<span>{ this._LOCALE.translate(locale___SbInfoCopyrightPage.copyright_extended_notice) }</span>
					</section>
				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbInfoCopyrightPage);