import React from 'react'

//	S T Y L E

import './com-spinner-tag.scss'

//	C L A S S

export default class ComSpinnerTagComponent extends React.Component<{}, {}>
{

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<i className="com-spinner-tag---component fa-solid fa-circle-notch"></i>
		</>;
	}

//#endregion

}