import React from 'react'
import { getPlatforms } from '@ionic/react';
import { Capacitor } from '@capacitor/core';

//	P R O P S

import { props___ComHeaderLogoComponent } from './com-header-logo.state';

//	S T Y L E

import './com-header-logo.scss'

//	T Y P E S

import { type___platform } from '../../types/types.platform';

//	C L A S S

export default class ComHeaderLogoComponent extends React.Component<props___ComHeaderLogoComponent, {}>
{

//#region 																							D E C L A R A T I O N S

	private should_display___header: boolean = true;

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComHeaderLogoComponent,
	) {
		super(props);
		this.should_display_header();
	}

//#endregion

//#region 																							G U I

	private should_display_header = () => {
		const ___platforms___isNativePlatform: boolean = Capacitor.isNativePlatform();
		const ___platforms___running: type___platform[] = getPlatforms();
		if (
			(!(___platforms___running.includes('mobileweb')) || ___platforms___isNativePlatform === true) &&
			!(___platforms___running.includes('android'))
		) {
			this.should_display___header = false;
		}
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className={ 'com-header-logo---component ' + ((this.should_display___header === true) ? ' ' : ' com-header-logo---hidden') }>
				{(() => { switch (this.props.logo___theme) {
					case 'dark': return <>
						<img src="/assets/logo.horizontal.light.svg"/>
					</>; break;
					default:
					case 'light': return <>
						<img src="/assets/logo.horizontal.svg"/>
					</>; break;
				}})()}
			</div>
		</>;
	}

//#endregion

}