//	L I B S

import { lib_api_shared } from '../libs/lib.api.shared';
import { lib_names } from '../libs/lib.names';
import { lib_names_shared } from '../libs/lib.names.shared';

//	T Y P E S

import { type___api___response } from '../types/types.api-response';
import { type___search___target } from '../types/types.types';
import { type___cookies___accepted } from '../types/types.cookies';
import { type___locale___language } from '../types/types.locale';

//	E X P O R T

export class service_RestApiService {

//#region 																							D E C L A R A T I O N S

	private _HEADERS: any = { 'Content-Type': lib_api_shared.content_type };

//#endregion

//#region 																							L O A D   P R O P S

	private load_props___language() : void
	{
		const ___language: type___locale___language | null = localStorage.getItem(lib_names.localStorage.user___language) as type___locale___language | null ?? null;
		if (___language) { this._HEADERS[lib_api_shared.headers.extra___x_locale] = ___language as type___locale___language; }
	}

	private load_props___tracking() : void
	{
		const ___cookies___accepted: type___cookies___accepted | null = localStorage.getItem(lib_names.localStorage.user___cookies_accepted) as type___cookies___accepted | null ?? null;
		if (___cookies___accepted) { this._HEADERS[lib_api_shared.headers.extra___x_tracking] = ___cookies___accepted as type___cookies___accepted; }
	}

	private load_props___geolocation() : void
	{
		const ___geolocation___stringified: string | null = localStorage.getItem(lib_names.localStorage.user___location);
		this._HEADERS[lib_api_shared.headers.extra___x_coords] = ___geolocation___stringified as string;
	}

//#endregion

//#region 																							R E T U R N   S E R V E R   R E S P O N S E

	private async return_server_response(___action: string, ___target: string, ___to_post: any | {}, ___signal: AbortSignal | null = null) : Promise<type___api___response>
	{

		this.load_props___language();
		this.load_props___tracking();
		this.load_props___geolocation();

	//	console.log('this._HEADERS',this._HEADERS)

		const ___s_route: string = lib_names_shared.endpoints.server_nearby + ___action + '/' + ___target;
		try {
			const ___s_response: Response = await fetch(___s_route, {
				method: 'POST',
				headers: this._HEADERS,
				body: JSON.stringify(___to_post),
				signal: ___signal
			});
			if (___s_response.ok) {
				try {
					const ___server_response: any = await ___s_response.json();
					return (___server_response !== null && ___server_response !== undefined) ? ___server_response : null;
				} catch (___e: any) {
					return { response: 'error', message: 'api-error', data: {} };
				}
			} else {
				return { response: 'error', message: 'api-error', data: {} };
			}
		} catch (___e_main: any) {
			console.error('Error fetching server response:', ___e_main);
			return { response: 'error', message: 'api-error', data: {} };
		}

	}

//#endregion

//#region 																							C A T A L O G   C A R S H U B

	async catalog_carshub___vehicles___read___multi(___poi_id: string, ___last_index: number) : Promise<type___api___response>
	{
		const ___to_post: any = { 'poi_id': ___poi_id, 'last_index': ___last_index };
		return await this.return_server_response('poi_catalog_carshub', 'vehicles/read_multi', ___to_post);
	}

	async catalog_carshub___vehicles___read___single(___vehicle_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'vehicle_id': ___vehicle_id };
		return await this.return_server_response('poi_catalog_carshub', 'vehicles/read_single', ___to_post);
	}

//#endregion

//#region 																							D I G I T A L M E N U S

	async digitalmenus___create(___poi_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'poi_id': ___poi_id };
		return await this.return_server_response('digitalmenus', 'create', ___to_post);
	}

	async digitalmenus___read___single(___target: 'poi' | 'digitalmenu', ___target_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'target': ___target, 'target_id': ___target_id };
		return await this.return_server_response('digitalmenus', 'read_single', ___to_post);
	}

//#endregion

//#region 																							D I G I T A L M E N U S   D I S H E S

	async digitalmenus_dishes___read___single(___dish_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'dish_id': ___dish_id };
		return await this.return_server_response('digitalmenus_dishes', 'read_single', ___to_post);
	}

//#endregion

//#region 																							D I G I T A L M E N U S   D I S H E S   A L L E R G E N S

	async digitalmenus_dishes_allergens___read___multi(___order: 'standard' | 'alphabetical' = 'standard') : Promise<type___api___response>
	{
		const ___to_post: any = { 'order': ___order };
		return await this.return_server_response('digitalmenus_dishes_allergens', 'read_multi', ___to_post);
	}

//#endregion

//#region 																							D I G I T A L M E N U S   D I S H E S   C A T E G O R I E S

	async digitalmenus_dishes_categories___read___multi(___order: 'standard' | 'alphabetical' = 'standard') : Promise<type___api___response>
	{
		const ___to_post: any = { 'order': ___order };
		return await this.return_server_response('digitalmenus_dishes_categories', 'read_multi', ___to_post);
	}

	async digitalmenus_dishes_categories___read___single(___dish_category_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'dish_category_id': ___dish_category_id };
		return await this.return_server_response('digitalmenus_dishes_categories', 'read_single', ___to_post);
	}

//#endregion

//#region 																							I N F O   D O C U M E N T S

	async info_documents___read(___doc_slug: 'changelog' | 'eula') : Promise<type___api___response>
	{
		const ___to_post: any = { 'doc_slug': ___doc_slug };
		return await this.return_server_response('info_documents', 'read', ___to_post);
	}

//#endregion

//#region 																							E V E N T S

	async events___read___multi(___search_target: string, ___search_param: string | null, ___last_index: number) : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_target': ___search_target, 'search_param': ___search_param, 'last_index': ___last_index };
		return await this.return_server_response('events', 'read_multi', ___to_post);
	}

	async events___read___single(___event_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'event_id': ___event_id };
		return await this.return_server_response('events', 'read_single', ___to_post);
	}

//#endregion

//#region 																							E V E N T S   A T T R I B U T E S

	async events_attributes___dresscodes___read___multi(___genres_ids: string[] = []) : Promise<type___api___response>
	{
		const ___to_post: any = { 'dresscodes_ids': ___genres_ids };
		return await this.return_server_response('events_attributes', 'dresscodes/read_multi', ___to_post);
	}

	async events_attributes___musicgenres___read___multi(___genres_ids: string[] = []) : Promise<type___api___response>
	{
		const ___to_post: any = { 'musicgenres_ids': ___genres_ids };
		return await this.return_server_response('events_attributes', 'musicgenres/read_multi', ___to_post);
	}

//#endregion

//#region 																							E V E N T S   C A T E G O R I E S

	async events_categories___read___multi() : Promise<type___api___response>
	{
		const ___to_post: any = { };
		return await this.return_server_response('events_categories', 'read_multi', ___to_post);
	}

	async events_categories___read___single(___e_category_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'event_category_id': ___e_category_id };
		return await this.return_server_response('events_categories', 'read_single', ___to_post);
	}

//#endregion

//#region 																							M A P

	async map___read___events(___map_center: [ number, number ], ___skip_ids: string[], ___signal: AbortSignal) : Promise<type___api___response>
	{
		const ___to_post: any = { 'map_center': ___map_center, 'skip_ids': ___skip_ids };
		return await this.return_server_response('map', 'read_events', ___to_post, ___signal);
	}

	async map___read___poi(___map_center: [ number, number ], ___skip_ids: string[], ___signal: AbortSignal) : Promise<type___api___response>
	{
		const ___to_post: any = { 'map_center': ___map_center, 'skip_ids': ___skip_ids };
		return await this.return_server_response('map', 'read_poi', ___to_post, ___signal);
	}

	async map___read___tales(___map_center: [ number, number ], ___skip_ids: string[], ___signal: AbortSignal) : Promise<type___api___response>
	{
		const ___to_post: any = { 'map_center': ___map_center, 'skip_ids': ___skip_ids };
		return await this.return_server_response('map', 'read_tales', ___to_post, ___signal);
	}

//#endregion

//#region 																							P O I

	async poi___read___multi(___search_target: string, ___search_param: string | null, ___last_index: number = 0) : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_target': ___search_target, 'search_param': ___search_param, 'last_index': ___last_index };
		return await this.return_server_response('poi', 'read_multi', ___to_post);
	}

	async poi___read___single(___p_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'p_id': ___p_id };
		return await this.return_server_response('poi', 'read_single', ___to_post);
	}

//#region 																							P O I   A T T R I B U T E S

	async poi_attributes___read___multi() : Promise<type___api___response>
	{
		const ___to_post: any = { };
		return await this.return_server_response('poi_attributes', 'read_multi', ___to_post);
	}

	async poi_attributes___read___single(___a_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'a_id': ___a_id };
		return await this.return_server_response('poi_attributes', 'read_single', ___to_post);
	}

//#endregion

//#region 																							P O I   C A T E G O R I E S

	async poi_categories___read___multi(___c_parent: string | null, ___c_read_children: boolean = false) : Promise<type___api___response>
	{
		const ___to_post: any = { 'c_parent': ___c_parent, 'c_read_children': ___c_read_children };
		return await this.return_server_response('poi_categories', 'read_multi', ___to_post);
	}

	async poi_categories___read___single(___c_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'c_id': ___c_id };
		return await this.return_server_response('poi_categories', 'read_single', ___to_post);
	}

//#endregion

//#region 																							P O S T S

	async posts___read___multi(___search_target: string , ___search_param: string | null, ___last_index: number, ___order: string = 'standard') : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_target': ___search_target, 'search_param': ___search_param, 'last_index': ___last_index, 'order': ___order };
		return await this.return_server_response('posts', 'read_multi', ___to_post);
	}

	async posts___read___single(___p_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'post_id': ___p_id };
		return await this.return_server_response('posts', 'read_single', ___to_post);
	}

//#endregion

//#region 																							S E A R C H

	async search___locations(___search_target: 'cities' | 'addresses', ___search_param: string, ___limit: number = lib_names.mongo.query_limit_few) : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_target': ___search_target, 'search_param': ___search_param, 'limit': ___limit };
		return await this.return_server_response('search', 'locations', ___to_post);
	}

	async search___discover(___search_param: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_param': ___search_param };
		return await this.return_server_response('search', 'discover', ___to_post);
	}

	async search___full(___search_target: type___search___target, ___search_param: string, ___last_index: number = 0) : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_target': ___search_target, 'search_param': ___search_param, 'last_index': ___last_index };
		return await this.return_server_response('search', 'full', ___to_post);
	}

//#endregion

//#region 																							S T A T S

	async stats___platform() : Promise<type___api___response>
	{
		const ___to_post: any = { };
		return await this.return_server_response('stats', 'platform', ___to_post);
	}

//#endregion

//#region 																							U S E R S

	async users___read___single(___search_target: 'username' | 'id' | 'me', ___search_param: string | null = null) : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_target': ___search_target, 'search_param': ___search_param };
		return await this.return_server_response('users', 'read_single', ___to_post);
	}

//#endregion

}