//	S T A T E

export interface state___SbInfoStatsPage {

	stats_platform___props___isLoading: boolean,

	stats___platform___poi_categories: number | null, 
	stats___platform___poi: number | null, 
	stats___platform___events: number | null, 
	
	stats___users___actives: number | null, 
	stats___users___tales: number | null, 
	stats___users___posts: number | null, 

}

//	S T A T E   D E F A U L T

export const state___SbInfoStatsPage___default: state___SbInfoStatsPage = {

	stats_platform___props___isLoading: true,

	stats___platform___poi_categories: null, 
	stats___platform___poi: null, 
	stats___platform___events: null, 
	
	stats___users___actives: null, 
	stats___users___tales: null, 
	stats___users___posts: null, 

}