/* 
 * 
 * 
 * 
 * 								L I B   S T O R E S
 * 
 * 
 * 
 */

export const lib_stores = {
	apple_app_store: 'https://apps.apple.com/us/app/nearby-community/id6633433866',
	google_play_store: 'https://play.google.com/store/apps/details?id=com.prysmlab.nearby',
}