export const locale___SbInfoStatsPage = {
	'title': {
		de_de: 'Plattformstatistiken',
		en_us: 'Platform Statistics',
		es_es: 'Estadísticas de la Plataforma',
		fr_fr: 'Statistiques de la Plateforme',
		it_it: 'Statistiche Piattaforma',
	},
	'stats___circa': {
		de_de: 'ca.',
		en_us: 'approx.',
		es_es: 'aprox.',
		fr_fr: 'env.',
		it_it: 'ca.',
	},
	'stats___platform___poi_categories': {
		de_de: 'Kategorien',
		en_us: 'Categories',
		es_es: 'Categorías',
		fr_fr: 'Catégories',
		it_it: 'Categorie',
	},
	'stats___platform___poi': {
		de_de: 'Orte von Interesse',
		en_us: 'Points of Interest',
		es_es: 'Puntos de Interés',
		fr_fr: 'Points d\'Intérêt',
		it_it: 'Punti di Interesse',
	},
	'stats___platform___events': {
		de_de: 'Aktuelle Ereignisse',
		en_us: 'Ongoing Events',
		es_es: 'Eventos en curso',
		fr_fr: 'Événements en cours',
		it_it: 'Eventi in corso',
	},
	'stats___users___actives': {
		de_de: 'Täglich aktive Nutzer',
		en_us: 'Daily Active Users',
		es_es: 'Usuarios Activos Diarios',
		fr_fr: 'Utilisateurs Actifs Quotidiens',
		it_it: 'Utenti attivi giornalmente',
	},
	'stats___users___tales': {
		de_de: 'Tales geteilte Erzählungen',
		en_us: 'Tales Shared Tales',
		es_es: 'Tales Compartidos Diariamente',
		fr_fr: 'Tales Partagés Quotidiennement',
		it_it: 'Tales condivise giornalmente',
	},
	'stats___users___posts': {
		de_de: 'Täglich geteilte Beiträge',
		en_us: 'Daily Shared Posts',
		es_es: 'Publicaciones Compartidas Diariamente',
		fr_fr: 'Publications Partagées Quotidiennement',
		it_it: 'Post condivisi giornalmente',
	},
	'stats___props___lastUpdate': {
		de_de: 'Letztes Statistik-Update:',
		en_us: 'Last update of statistics:',
		es_es: 'Última actualización de las estadísticas:',
		fr_fr: 'Dernière mise à jour des statistiques:',
		it_it: 'Ultimo aggiornamento delle statistiche:',
	},
};