export const locale___MdEntryCookiesModal = {
	'title': {
		de_de: 'Möchten Sie die Verwendung von Cookies durch nearby zulassen?',
		en_us: 'Do you consent to the use of cookies on nearby?',
		es_es: '¿Quieres permitir que nearby utilice cookies?',
		fr_fr: 'Souhaitez-vous autoriser l\'utilisation de cookies à nearby?',
		it_it: 'Vuoi consentire l\'utilizzo dei cookies a nearby?',
	},
	'description_1': {
		de_de: 'In Übereinstimmung mit den geltenden Vorschriften zur Verarbeitung personenbezogener Daten und der Einhaltung der Datenschutz-Grundverordnung (DSGVO) möchten wir Sie darüber informieren, dass unsere App Cookies und andere ähnliche Technologien verwendet, um Ihr Surferlebnis zu verbessern',
		en_us: 'In compliance with current regulations on the processing of personal data and the General Data Protection Regulation (GDPR), we would like to inform you that our app uses cookies and similar technologies to enhance your browsing experience.',
		es_es: 'De acuerdo con la normativa vigente en materia de tratamiento de datos personales y cumplimiento del Reglamento General de Protección de Datos (RGPD), te informamos que nuestra aplicación utiliza cookies y otras tecnologías similares para mejorar tu experiencia de navegación.',
		fr_fr: 'Conformément à la réglementation en vigueur sur le traitement des données personnelles et au respect du Règlement Général sur la Protection des Données (RGPD), nous souhaitons vous informer que notre application utilise des cookies et autres technologies similaires pour améliorer votre expérience de navigation.',
		it_it: 'In conformità con le norme vigenti sul trattamento dei dati personali e il rispetto del Regolamento Generale sulla Protezione dei Dati (GDPR), desideriamo informarti che la nostra app utilizza i cookie ed altre tecnologie simili per migliorare la tua esperienza di navigazione.',
	},
	'description_2': {
		de_de: 'Wir verwenden Cookies auch, um Inhalte zu personalisieren, Social-Media-Funktionen anzubieten und den Verkehr auf unserer Web-App zu analysieren.',
		en_us: 'We also use cookies to personalize content, provide social media features, and analyze traffic on our webapp.',
		es_es: 'También utilizamos cookies para personalizar el contenido, ofrecer funciones de redes sociales y analizar el tráfico en nuestra aplicación web.',
		fr_fr: 'Nous utilisons également des cookies pour personnaliser le contenu, fournir des fonctionnalités de réseaux sociaux et analyser le trafic sur notre application Web.',
		it_it: 'Utilizziamo i cookie anche per personalizzare i contenuti, offrire funzionalità di social media e analizzare il traffico della nostra webapp.',
	},
	'more_info': {
		de_de: 'Weitere Informationen finden Sie in den vollständigen Informationen',
		en_us: 'For more information, please read the full privacy policy',
		es_es: 'Para más información lee la información completa',
		fr_fr: 'Pour plus d’informations, lisez les informations complètes',
		it_it: 'Per avere maggiori informazioni leggi l\'informativa completa',
	},
	'warning': {
		de_de: 'Wenn Sie nur teilweise zustimmen oder ablehnen, können Sie Ihr Erlebnis mit der Plattform gefährden',
		en_us: 'By partially accepting or rejecting, you may compromise your experience with the platform',
		es_es: 'Al aceptar o rechazar solo parcialmente, puedes comprometer tu experiencia con la plataforma.',
		fr_fr: 'En acceptant ou en refusant partiellement, vous pouvez compromettre votre expérience avec la plateforme',
		it_it: 'Accettando solo parzialmente o rifiutando puoi compromettere la tua esperienza con la piattaforma',
	},
	'button___accept': {
		de_de: 'Akzeptieren Sie alle Cookies',
		en_us: 'Accept All Cookies',
		es_es: 'Aceptar todas las cookies',
		fr_fr: 'Acceptez tous les cookies',
		it_it: 'Accetta tutti i cookies',
	},
	'button___reject': {
		de_de: 'Optionale Cookies ablehnen',
		en_us: 'Reject optional Cookies',
		es_es: 'Rechazar cookies opcionales',
		fr_fr: 'Rejeter les cookies facultatifs',
		it_it: 'Rifiuta i cookies facoltativi',
	},
};