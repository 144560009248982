/* 
 * 
 * 
 * 
 * 								L I B   E R R O R S   G E O L O C A T I O N
 * 
 * 
 * 
 */

export const lib_errors_geolocation = {

	geolocation___not_supported: 'geolocation___not_supported',
	geolocation___denied: 'geolocation___denied',
	geolocation___unavailable: 'geolocation___unavailable',
	geolocation___timed_out: 'geolocation___timed_out',
	geolocation___generic: 'geolocation___generic',

}