//	E X P O R T   L O C A L E   -->   funcs_measurements

export const locale___funcs_measurements = {
	'unit___g': {
		de_de: '/ g',
		en_us: '/ g',
		es_es: '/ g',
		fr_fr: '/ g',
		it_it: '/ g',
	},
	'unit___g___extended': {
		de_de: 'pro gramm',
		en_us: 'per gram',
		es_es: 'por gramo',
		fr_fr: 'par gramme',
		it_it: 'al grammo',
	},
	'unit___kg': {
		de_de: '/ kg',
		en_us: '/ kg',
		es_es: '/ kg',
		fr_fr: '/ kg',
		it_it: '/ kg',
	},
	'unit___kg___extended': {
		de_de: 'pro kilo',
		en_us: 'per kilo',
		es_es: 'por kilo',
		fr_fr: 'par kilo',
		it_it: 'al chilo',
	},
	'unit___l': {
		de_de: '/ l',
		en_us: '/ l',
		es_es: '/ l',
		fr_fr: '/ l',
		it_it: '/ l',
	},
	'unit___l___extended': {
		de_de: 'pro liter',
		en_us: 'per liter',
		es_es: 'por litro',
		fr_fr: 'par litre',
		it_it: 'al litro',
	},
};