import { type___map___element___type } from "../../types/types.types"

//	P R O P S

export interface props___ComDiscoverElementComponent {

	element___type: type___map___element___type,
	element___id: string | null,
	element___props: any | null,

}

//	S T A T E

export interface state___ComDiscoverElementComponent {

	element___props___id: string | null
	element___props___type: type___map___element___type | null,
	element___props: any | null,
	element___props___isLoading: boolean,

}

//	S T A T E   D E F A U L T

export const state___ComDiscoverElementComponent___default: state___ComDiscoverElementComponent = {

	element___props___id: null,
	element___props___type: null,
	element___props: null,
	element___props___isLoading: true,

}