//	L O C A L E S

import { locale___funcs_datetime } from "./funcs.datetime.locale";

//	T Y P E S

import { type___poi___timetable___day_index, type___poi___timetable___hour, type___poi___timetable___week } from "../types/types.types";

//	S E R V I C E S

import { service_LocalizationService } from "../services/service-localization";

//	E X P O R T   F U N C S

export class funcs_datetime
{

//#region 																							N U M B E R S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							D A T E T I M E   G E T   D A Y   O F   T H E   W E E K

	datetime___cut_SZ(___datestring_ISOString: string) : string
	{

		const ___datetime___parts: string[] = ___datestring_ISOString.split('T');

		const ___datetime___date: string = ___datetime___parts[0];
		const ___datetime___time: string = ___datetime___parts[1].substring(0, 5)
	
		return ___datetime___date + 'T' + ___datetime___time;
	
	}

//#endregion

//#region 																							D A T E T I M E   G E T   D A Y   O F   T H E   W E E K

	datetime___get___dayOfTheWeek(___day_identifier: type___poi___timetable___day_index) : string
	{
		const ___day_name___translated: string = this._LOCALE.translate(locale___funcs_datetime[___day_identifier as string]);
		return ___day_name___translated;
	}

//#endregion

//#region 																							D A T E T I M E   G E T   D A Y   O F   T H E   W E E K   I N D E X

	datetime___get___dayOfTheWeek___index(___index: number) : type___poi___timetable___day_index
	{
		const ___days: type___poi___timetable___day_index[] = [ 'sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat' ];
		const ___day___index: type___poi___timetable___day_index = ___days[___index];
		return ___day___index;
	}

//#endregion

//#region 																							D A T E T I M E   G E T   D A Y   O F   T H E   W E E K

	datetime___get___isOpen(___time: type___poi___timetable___week) : boolean
	{
		const ___day_today: Date = new Date();
		const ___day_today___index: type___poi___timetable___day_index = this.datetime___get___dayOfTheWeek___index(___day_today.getDay());
		for (let ___t___idx = 0; ___t___idx < ___time[___day_today___index].length; ___t___idx++)
		{
			const ___time___single: type___poi___timetable___hour = ___time[___day_today___index][___t___idx];
			const ___time___single___from___hm: number[] = ___time___single['from'].split(':').map(Number);
			const ___time___single___from: Date = new Date(___day_today.getFullYear(), ___day_today.getMonth(), ___day_today.getDate(), ___time___single___from___hm[0], ___time___single___from___hm[1]);
			const ___time___single___to___hm: number[] = ___time___single['to'].split(':').map(Number);
			const ___time___single___to: Date = new Date(___day_today.getFullYear(), ___day_today.getMonth(), ___day_today.getDate(), ___time___single___to___hm[0], ___time___single___to___hm[1]);
			if (___day_today >= ___time___single___from && ___day_today <= ___time___single___to) { return true; }
		}
		return false;
	}

//#endregion

//#region 																							D A T E T I M E   G E T   D A T E

	datetime___get___date(___datetime_string: Date | string) : string
	{
		const ___datetime_obj: Date = new Date(___datetime_string);
		const ___d___day: string = ___datetime_obj.getDate().toString().padStart(2, '0');
		const ___d___month: string = (___datetime_obj.getMonth() + 1).toString().padStart(2, '0');
		const ___d___year: string = ___datetime_obj.getFullYear().toString();
		return ___d___day.toString() + '/' + ___d___month.toString() + '/' + ___d___year.toString();
	}

//#endregion

//#region 																							D A T E T I M E   G E T   T I M E

	datetime___get___time(___datetime_string: Date | string) : string
	{
		const ___datetime_obj: Date = new Date(___datetime_string);
		const ___d___hours: string = ___datetime_obj.getHours().toString().padStart(2, '0');
		const ___d___minutes: string = ___datetime_obj.getMinutes().toString().padStart(2, '0');
		return ___d___hours.toString() + ':' + ___d___minutes.toString();
	}

//#endregion

//#region 																							D A T E T I M E   G E T   A G E

	datetime___get___age(___datetime_string: Date | string, ___abbreviation: boolean = false) : string
	{
		
		const ___datetime___now = new Date();
		const ___datetime___target: Date = new Date(___datetime_string);
		const ___datetime___unit___is_abbreviated: string = (___abbreviation === true) ? '___abbr' : '';

		const ___difference___seconds: number = Math.floor((___datetime___now.getTime() - ___datetime___target.getTime()) / 1000);
		const ___difference___minutes = Math.floor(___difference___seconds / 60);
		const ___difference___hours = Math.floor(___difference___minutes / 60);
		const ___difference___days = Math.floor(___difference___hours / 24);
		const ___difference___weeks = Math.floor(___difference___days / 7);
		const ___difference___months = Math.floor(___difference___days / 30);
		const ___difference___years = Math.floor(___difference___days / 365);

		if (___difference___seconds < 30) { return this._LOCALE.translate(locale___funcs_datetime['time_ago___now' + ___datetime___unit___is_abbreviated]); }
		if (___difference___minutes < 60) { return this._LOCALE.translate(locale___funcs_datetime['time_ago___minutes' + ___datetime___unit___is_abbreviated], [___difference___minutes.toString()]); }
		if (___difference___hours < 60) { return this._LOCALE.translate(locale___funcs_datetime['time_ago___hours' + ___datetime___unit___is_abbreviated], [___difference___hours.toString()]); }
		if (___difference___days < 60) { return this._LOCALE.translate(locale___funcs_datetime['time_ago___days' + ___datetime___unit___is_abbreviated], [___difference___days.toString()]); }
		if (___difference___weeks < 60) { return this._LOCALE.translate(locale___funcs_datetime['time_ago___weeks' + ___datetime___unit___is_abbreviated], [___difference___weeks.toString()]); }
		if (___difference___months < 60) { return this._LOCALE.translate(locale___funcs_datetime['time_ago___months' + ___datetime___unit___is_abbreviated], [___difference___months.toString()]); }
		if (___difference___years < 60) { return this._LOCALE.translate(locale___funcs_datetime['time_ago___years' + ___datetime___unit___is_abbreviated], [___difference___years.toString()]); }

	}

//#endregion

//#region 																							D A T E T I M E   I S   1 4   Y E A R S   O L D   O R   M O R E

	datetime___is14YearsOldOrMore(___date___birth___stringified: string): boolean
	{

		const ___date___current = new Date();
		const ___date___birth = new Date(___date___birth___stringified);
	
		let ___age = ___date___current.getFullYear() - ___date___birth.getFullYear();
	
		const ___date_calc___birthMonth = ___date___birth.getMonth();
		const ___date_calc___birthDay = ___date___birth.getDate();
		const ___date_calc___currentMonth = ___date___birth.getMonth();
		const ___date_calc___currentDay = ___date___birth.getDate();
	
		if (___date_calc___currentMonth < ___date_calc___birthMonth || (___date_calc___currentMonth === ___date_calc___birthMonth && ___date_calc___currentDay < ___date_calc___birthDay)) {
			___age--;
		}
	
		return ___age >= 14;
	}

//#endregion

}