import React from 'react'

//	S T Y L E

import './com-text.scss'

//	S T A T E S   -   P R O P S

import { props___ComTextComponent } from './com-text.state';

//	F U N C T I O N S

import { funcs_text } from '../../funcs/funcs.text';

//	C L A S S

export default class ComTextComponent extends React.Component<props___ComTextComponent, {}>
{

//#region 																							D E C L A R A T I O N S 

	private readonly funcs___text: funcs_text = new funcs_text();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="com-text-component---container">
				<p>{ this.funcs___text.text_input___process___render(this.props.text) }</p>
			</div>
		</>;
	}

//#endregion

}