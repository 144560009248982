import React from 'react'
import { IonCard } from '@ionic/react';

//	S T Y L E

import './com-user.scss'

//	S T A T E S   -   P R O P S

import { props___ComUserComponent } from './com-user.state';

//	C O M P O N E N T S

import ComUserVerifiedComponent from '../com-user-verified/com-user-verified';

//	C L A S S

export default class ComUserComponent extends React.Component<props___ComUserComponent, {}> {

//#region 																							R E N D E R

	render() : React.ReactNode {
		return <>
			{(this.props) ? <>
				<IonCard className="com-user-component---container">
					<img src={ this.props.user___props['user_image_profile'] }/>
					<section>
						<h5>
							<span>{ this.props.user___props['user_username'] }</span>
							{(this.props.user___props['user_profile_verified'] && this.props.user___props['user_profile_verified'] === true) ? <>
								<ComUserVerifiedComponent popup={ false } />
							</> : <></>}
						</h5>
					</section>
				</IonCard>
			</> : <></>}
		</>;
	}

//#endregion

}