import { getPlatforms } from '@ionic/react';

//	E X P O R T   F U N C S

export class funcs_platform {

//#region 																							G E T   T Y P E

	public get_type() : 'ios' | 'android' | null {
		const ___platforms: string[] = getPlatforms();
		if (___platforms.includes('android')) { return 'android'; }
		if (___platforms.includes('ios')) { return 'ios'; }
		return null;
	}

//#endregion

}