import React from 'react'
import mapboxgl from 'mapbox-gl';
import { IonActionSheet, IonCard } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___ComMapPreviewComponent } from './com-map-preview.locale';

//	S T Y L E

import 'mapbox-gl/dist/mapbox-gl.css';
import './com-map-preview.scss';

//	L I B S

import { lib_names } from '../../libs/lib.names';

//	I N T E R F A C E S

import { interface___IonActionSheet } from '../../interfaces/interface.actionsheet';

//	S T A T E   -   P R O P S

import { props___ComMapPreviewComponent, state___ComMapPreviewComponent, state___ComMapPreviewComponent___default } from './com-map-preview.state';

//	 S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class ComMapPreviewComponent extends React.Component<props___ComMapPreviewComponent, state___ComMapPreviewComponent>
{

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

	private readonly mapbox___map___container: React.RefObject<HTMLDivElement> = React.createRef();
	private mapbox___map___loaded: boolean = false;
	private mapbox___map: mapboxgl.Map;
	private mapbox___map___marker: mapboxgl.Marker;

//#endregion

//#region 																							C O N F I D

	private readonly IonActionSheet___mapNavigation___config: interface___IonActionSheet = {
		header: this._LOCALE.translate(locale___ComMapPreviewComponent.go_to_navigation),
		buttons: [
			{
				text: this._LOCALE.translate(locale___ComMapPreviewComponent.go_to_navigation___apple_maps),
				handler: () => { 
					const ___navigation_url___ios: string = 'http://maps.apple.com/?ll=' + this.props.map_coords.lat.toString() + ',' + this.props.map_coords.lng.toString();
					const ___navigation_url___ios___window: WindowProxy | null = window.open(___navigation_url___ios, '_blank');
					___navigation_url___ios___window.focus();
				}
			},
			{
				text: this._LOCALE.translate(locale___ComMapPreviewComponent.go_to_navigation___google_maps),
				handler: () => { 
					const ___navigation_url___android: string = 'https://www.google.com/maps?q=' + this.props.map_coords.lat.toString() + ',' + this.props.map_coords.lng.toString();
					const ___navigation_url___android___window: WindowProxy | null = window.open(___navigation_url___android, '_blank');
					___navigation_url___android___window.focus();
				}
			},
			{
				text: this._LOCALE.translate(locale___ComMapPreviewComponent.go_to_navigation___cancel),
				role: 'cancel',
			}
		]
	};

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComMapPreviewComponent,
	) {
		super(props);
		this.state = state___ComMapPreviewComponent___default;
	}

//#endregion

//#region 																							M A P

	private map___prepare = () => {
		if (this.mapbox___map___loaded !== true) {

			this.mapbox___map = new mapboxgl.Map({
				accessToken: lib_names.mapboxgl.accessToken,
				container: this.mapbox___map___container.current as HTMLElement,
				style: lib_names.mapboxgl.style_normal,
				center: [this.state.coords.lng, this.state.coords.lat],
				zoom: lib_names.mapboxgl.zoomPreview,
			});

			this.mapbox___map___marker = new mapboxgl.Marker({ color: '#fd4755' })
				.setLngLat({ lng: this.props.map_coords.lng, lat: this.props.map_coords.lat })
				.addTo(this.mapbox___map);

			this.mapbox___map.resize();
			this.mapbox___map___loaded = true;

		}
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___open___map_route = () => {
		if (this.props.canOpenRoute === true) {
			this.setState({
				ionActionSheet___mapNavigation___isOpen: true
			});
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount() : void
	{
		this.setState({
			coords: this.props.map_coords
		}, () => {
			this.map___prepare();
		});
	}

	componentDidUpdate(prevProps: props___ComMapPreviewComponent, prevState: state___ComMapPreviewComponent) : void
	{
		if (this.props !== prevProps) {
			this.setState({
				coords: this.props.map_coords
			}, () => {
				this.mapbox___map.setCenter({ lng: this.state.coords.lng, lat: this.state.coords.lat });
				this.mapbox___map___marker.setLngLat({ lng: this.state.coords.lng, lat: this.state.coords.lat });
			});
		}
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>

			<IonActionSheet isOpen={ this.state.ionActionSheet___mapNavigation___isOpen } {...this.IonActionSheet___mapNavigation___config} onDidDismiss={() => { this.setState({ ionActionSheet___mapNavigation___isOpen: false }); }} />

			<IonCard className="com-map-preview---component" onClick={ this.handle___open___map_route }>
				<div className="is-element is-card no-padding no-shadow">
					<div>
						<div ref={ this.mapbox___map___container } className="com-map-preview---map"/>
					</div>
				</div>
			</IonCard>

		</>;
	}

//#endregion

}