//	P R O P S

export interface props___ComGallerySliderComponent {

	gallery___images: string[],
	image___aspect_ratio: number,

}

//	S T A T E 

export interface state___ComGallerySliderComponent {

	ionModal___MdMediaViewModal___visible: boolean,
	ionModal___MdMediaViewModal___argument: string,

}

//	S T A T E   D E F A U L T

export const state___ComGallerySliderComponent___default: state___ComGallerySliderComponent = {

	ionModal___MdMediaViewModal___visible: false,
	ionModal___MdMediaViewModal___argument: '',

}
