//	P R O P S

export interface props___ComMarkdownComponent {

	file_path: string;

}

//	S T A T E

export interface state___ComMarkdownComponent {

	markdown_content: string,

}

//	S T A T E   D E F A U L T S

export const state___ComMarkdownComponent___defaults: state___ComMarkdownComponent = {

	markdown_content: ''

}