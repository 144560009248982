import { RouteComponentProps } from "react-router";

//	P R O P S

export interface props___SbEventsCategoryViewPage extends RouteComponentProps<{ id: string; }> {

}

//	S T A T E

export interface state___SbEventsCategoryViewPage {

	events_category___id: 'all' | string,
	events_category___props: any | null,
	events_category___props___isLoading: boolean,

	events_category___events_collection___target: 'all' | 'category',
	events_category___events_collection: any[],
	events_category___events_collection___isLoading: boolean,
	events_category___events_collection___is_complete: boolean,











}

//	S T A T E   D E F A U L T

export const state___SbEventsCategoryViewPage___default: state___SbEventsCategoryViewPage = {

	events_category___id: '',
	events_category___props: [],
	events_category___props___isLoading: true,

	events_category___events_collection___target: 'category',
	events_category___events_collection: [],
	events_category___events_collection___isLoading: true,
	events_category___events_collection___is_complete: false,











}