import { interface___modal } from "../../interfaces/interface.modals";

//	P R O P S

export interface props___MdEntryDownloadAppModal extends interface___modal {

}

//	S T A T E

export interface state___MdEntryDownloadAppModal {

	platform_type: 'android' | 'ios' | null,
	platform_type___isLoading: boolean,

}

//	S T A T E   D E F A U L T

export const state___MdEntryDownloadAppModal___default: state___MdEntryDownloadAppModal = {
	
	platform_type: null,
	platform_type___isLoading: true,

}