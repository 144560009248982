import { RouteComponentProps } from "react-router";

//	P R O P S

export interface props___SbPoiCategoryViewPage extends RouteComponentProps<{ id: string; }> {

}

//	S T A T E

export interface state___SbPoiCategoryViewPage {

	poi_category___id: string,
	poi_category___props: any | null,
	poi_category___props___isLoading: boolean,

	poi_category___poi_collection: any[],
	poi_category___poi_collection___isLoading: boolean,
	poi_category___poi_collection___is_complete: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbPoiCategoryViewPage___default: state___SbPoiCategoryViewPage = {

	poi_category___id: '',
	poi_category___props: [],
	poi_category___props___isLoading: true,

	poi_category___poi_collection: [],
	poi_category___poi_collection___isLoading: true,
	poi_category___poi_collection___is_complete: false,

}