import { interface___modal } from "../../interfaces/interface.modals";

//	P R O P S

export interface props___MdDiscoverCompassComponent extends interface___modal {


}

//	S T A T E

export interface state___MdDiscoverCompassComponent {

	categories_primary___collection: any[],
	categories_primary___selected: any | null,

	categories_secondary___collection: any[],
	categories_secondary___events___collection: any[],

}

//	S T A T E   D E F A U L T

export const state___MdDiscoverCompassComponent___default: state___MdDiscoverCompassComponent = {

	categories_primary___collection: [],
	categories_primary___selected: null,

	categories_secondary___collection: [],
	categories_secondary___events___collection: [],

}