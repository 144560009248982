export const locale___ComPoiDisclaimerAcceptanceComponent = {
	'title': {
		de_de: 'Wird überprüft',
		en_us: 'Under review',
		es_es: 'En revisión',
		fr_fr: 'En cours de révision',
		it_it: 'In fase di verifica',
	},
	'disclaimer': {
		de_de: 'Da dieser Punkt von Interesse und sein Inhalt überprüft werden, kümmert sich das Nearby-Personal darum, sicherzustellen, dass alle Informationen echt und wahrheitsgetreu sind',
		en_us: 'this point of interest and its contents are being verified, the Nearby staff is taking care of verifying that all the information is real and truthful',
		es_es: 'Este punto de interés y su contenido están siendo verificados, el personal de Near se está encargando de comprobar que toda la información es real y veraz',
		fr_fr: 'ce point d\'intérêt et son contenu sont en cours de vérification, le personnel à proximité se charge de vérifier que toutes les informations sont réelles et véridiques',
		it_it: 'questo punto di interesse ed i suoi contenuti sono in fase di verifica, lo staff di Nearby si sta occupando di verificare che tutte le informazioni siano reali e veritiere',
	},
	'duration': {
		de_de: 'Dieser Vorgang kann bis zu 3 Werktage dauern',
		en_us: 'this procedure can take up to 3 working days',
		es_es: 'este trámite puede demorar hasta 3 días hábiles',
		fr_fr: 'cette procédure peut prendre jusqu\'à 3 jours ouvrables',
		it_it: 'questa procedura può durare fino a 3 giorni lavorativi',
	},
};