export const locale___ComPoiEmergencyComponent = {
	'row_1': {
		de_de: 'Wenn Sie einen Notfall haben, zögern Sie nicht',
		en_us: 'If you have an emergency, don\'t hesitate',
		es_es: 'Si tienes una emergencia, no lo dudes',
		fr_fr: 'Si vous avez une urgence, n\'hésitez pas',
		it_it: 'Se hai una emergenza, non esitare',
	},
	'row_2': {
		de_de: 'Rufen Sie sofort {{1}} an',
		en_us: 'Call now {{1}}',
		es_es: 'Llama al {{1}} inmediatamente',
		fr_fr: 'Appelez immédiatement le {{1}}',
		it_it: 'Chiama subito il {{1}}',
	},
};