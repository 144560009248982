//	E X P O R T   L O C A L E   -->   funcs_geolocation

export const locale___service_Geolocation = {
	'address_not_availble': {
		de_de: 'Adresse nicht verfügbar',
		en_us: 'Address not available',
		es_es: 'Dirección no disponible',
		fr_fr: 'Adresse non disponible',
		it_it: 'Indirizzo non disponibile',
	},
};